import uuidv4 from '../helpers/uuidv4';
import ApiService from './ApiService';
import ServiceRoutes from './ServiceRoutes';

const SessionService = {
  async Login(values) {
    const body = {
      username: values.email,
      password: values.password,
      persist: true,
    };
    return ApiService(ServiceRoutes.auth.signIn, 'post', '', body);
  },
  async Stats() {
    const body = {};
    return ApiService(ServiceRoutes.dashboard.stats, 'post', '', body);
  },
  async Statics() {
    const body = {};
    return ApiService(ServiceRoutes.admin.get, 'post', '', body);
  },
  async RequestList({ page, perPage, status, eligibility }) {
    const body = {};
    return ApiService(ServiceRoutes.dashboard.requestList(page, perPage, status, eligibility), 'post', '', body);
  },
  async ListInstitution() {
    const body = {};
    return ApiService(ServiceRoutes.admin.listInstitution, 'post', '', body);
  },
  async CreateInsitution(values, groupeId) {
    const body = {
      manager_name: values.managerName,
      email: values.email,
      phone: `+225${values.phone}`,
      legal_name: values.legalName,
      location: values.location,
      core_business_area: values.core,
      compagny_creation_date: '2023/2/25',
      leasing_institution_group_id: parseInt(groupeId, 10),
    };
    return ApiService(ServiceRoutes.admin.createInsitution, 'post', '', body);
  },
  async UserInfo() {
    const body = {};
    return ApiService(ServiceRoutes.auth.userInfo, 'post', '', body);
  },
  async CreatRequest(values) {
    const body = {
      ...values,
    };
    return ApiService(ServiceRoutes.request.createRequest, 'post', '', body);
  },
  async CreateAssitance(uuid) {
    const body = {
      lease_uuid: uuid,
    };

    return ApiService(ServiceRoutes.request.createAssistance, 'post', '', body);
  },
  async AddDocument(uuid, values) {
    const body = {
      leasing_request_id: uuid,
      financial_statements: values,
    };

    return ApiService(ServiceRoutes.request.AddDocument, 'post', '', body);
  },
  async CreateGroupe(values) {
    const body = {
      priority_level: values.priority_level,
      description: values.description,
      group_uuid: uuidv4(),
    };
    return ApiService(ServiceRoutes.groupe.createGroupe, 'post', '', body);
  },
  async ListGroupe() {
    const body = {};
    return ApiService(ServiceRoutes.groupe.listGroupe, 'post', '', body);
  },
  async UpdateGroupe(values) {
    const body = {
      institution_tenant_id: values.institution_tenant_id,
      group_id: values.group_id,
    };

    return ApiService(ServiceRoutes.groupe.updateGroupe, 'post', '', body);
  },
  async AllUser() {
    const body = {};
    return ApiService(ServiceRoutes.auth.getAllUser, 'post', '', body);
  },
  async UpdateRequest(values) {
    const body = {
      lease_uuid: values.lease_uuid,
      // (-1: rejeté, 0: correction, 1: accepté),
      response: values.response,
      details: values.content !== undefined ? values.content : '',
    };
    return ApiService(ServiceRoutes.request.updateRequest, 'post', '', body);
  },

  async CreateUnderAdmin(values) {
    const body = {
      manager_name: values.managerName,
      email: values.email,
      phone: `+225${values.phone}`,
      phone2: values?.phone2 ?? '',
    };
    return ApiService(ServiceRoutes.inst.createUnderAdmin, 'post', '', body);
  },
  async CreateMessage(values) {
    const body = {
      receiver: values.id,
      message: values.message,
    };

    return ApiService(ServiceRoutes.message.createMessage, 'post', '', body);
  },
  async GetMessage(id) {
    const body = {};
    return ApiService(ServiceRoutes.message.getMessage(id), 'post', '', body);
  },

  async GetAdmin() {
    const body = {};

    return ApiService(ServiceRoutes.auth.getAdmin, 'post', '', body);
  },
};

export default SessionService;

/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { Box, FormControl, FormLabel, Stack, Typography, TextField, Button, Select, MenuItem } from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import randomkey from '../../helpers/randomKey';
import { deleteLoader, setLoader } from '../../feature/Loader/LoaderSlice';
import SessionService from '../../Services/SessionService';
import { setAlert } from '../../feature/Alert/AlertSlice';
import alertMock from '../../_mock/alert';
import { selectDashboard } from '../../feature/Dasboard/DashboardSlice';
import RealoadAdminInst from '../Reload/RealoadAdminInst';

const NewInstitution = ({ handleClose }) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const dispatch = useDispatch();
  const loaderKey = randomkey();
  const { groupeList } = useSelector(selectDashboard);
  const [groupeId, setGroupeId] = React.useState();
  const [reload, setReload] = React.useState(false);
  const initialValues = {
    managerName: '',
    email: '',
    phone: '',
    legalName: '',
    location: "cote d'ivoire",
    core: 'Finance',
    compagny_creation_date: '2023/2/25',
  };

  const validationSchema = yup.object({
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    managerName: yup.string('Ce champ doit être en chaîne de caractères').required('Ce champ est obligatoire'),
    phone: yup.string().matches(phoneRegExp, "le numéro de téléphone n'est pas valide"),
    legalName: yup.string('Ce champ doit être en chaîne de caractères').required('Ce champ est obligatoire'),
    core: yup.string('Ce champ doit être en chaîne de caractères').required('Ce champ est obligatoire'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => handleClick(values),
  });

  const handleClick = (values) => {
    dispatch(setLoader({ key: loaderKey }));
    SessionService.CreateInsitution(values, groupeId)
      .then((values) => {
        dispatch(deleteLoader({ key: loaderKey }));
        const errorStatus = values.data.error;
        const { data } = values.data;
        if (errorStatus === false) {
          dispatch(setAlert({ state: 'success', message: data, time: 15000 }));
          setReload(true);
          handleClose();
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: loaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      {reload === true && <RealoadAdminInst />}
      <Typography variant="h4" sx={{ marginBottom: '10px' }}>
        Formulaire de demande de nouveau crédit.
      </Typography>
      <Box>
        <Typography variant="h5" sx={{ mb: 2, mt: 3 }}>
          Les Informations
        </Typography>
        <Typography variant="h6" sx={{ mb: 5 }}>
          Saisissez les informations nécessaires concernant votre demande de crédit afin de vérifier votre éligibilité.
          <span style={{ color: 'red' }}> *</span>
        </Typography>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems="flex-start"
          sx={{ width: '100%', margin: '5vh auto auto auto ', flexWrap: 'wrap', rowGap: '20px' }}
          component={'form'}
          onSubmit={formik.handleSubmit}
        >
          {' '}
          <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
            <FormLabel>Veuillez indiquer le nom du responsable</FormLabel>
            <TextField
              id="managerName"
              name="managerName"
              value={formik.values.managerName}
              onChange={formik.handleChange}
              error={formik.touched.managerName && Boolean(formik.errors.managerName)}
              helperText={formik.touched.managerName && formik.errors.managerName}
              required
            />
          </FormControl>
          <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
            <FormLabel>Veuillez entrer Votre e-mail</FormLabel>
            <TextField
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              type="email"
              required
            />
          </FormControl>
          <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
            <FormLabel>Veuillez saisir votre numero de telephone</FormLabel>
            <TextField
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              required
              type={'tel'}
            />
          </FormControl>
          <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
            <FormLabel>Veuillez saisir le nom de l'entreprise</FormLabel>
            <TextField
              id="legalName"
              name="legalName"
              value={formik.values.legalName}
              onChange={formik.handleChange}
              error={formik.touched.legalName && Boolean(formik.errors.legalName)}
              helperText={formik.touched.legalName && formik.errors.legalName}
            />
          </FormControl>
          {/* <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
            <FormLabel 
            >Veuillez saisir la ville où se trouve votre entreprise.</FormLabel>
            <TextField
              id="location"
              name="location"
              value={formik.values.location}
              onChange={formik.handleChange}
              error={formik.touched.location && Boolean(formik.errors.location)}
              helperText={formik.touched.location && formik.errors.location}
            />
          </FormControl> */}
          {/* <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
            <FormLabel>Veuillez saisir votre domaine d'activité principal</FormLabel>
            <TextField
              id="core"
              name="core"
              value={formik.values.core}
              onChange={formik.handleChange}
              error={formik.touched.core && Boolean(formik.errors.core)}
              helperText={formik.touched.core && formik.errors.core}
            />
          </FormControl> */}
          <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
            <FormLabel>Le groupe de l'institution</FormLabel>
            <Select
              id="demo-simple-select"
              value={formik.values.leasing_institution_group_id}
              label="Le groupe de l'institution "
              onChange={(e) => setGroupeId(e.target.value)}
              required
            >
              {groupeList.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.priority}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" sx={{ width: '100%' }} type="submit">
            Soumettre
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default NewInstitution;

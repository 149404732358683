/* eslint-disable consistent-return */
import { Alert } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAlert, selectAlert } from './AlertSlice';

const AlertCustomize = () => {
  const alertStyle = {
    width: { xs: '95%', md: '60%' },
    margin: '8vh 2.5% auto 2.5%',
    position: 'fixed',
    right: '0',
    zIndex: '99999999',
  };

  const alertItems = useSelector(selectAlert);

  React.useEffect(() => {
    if (alertItems.length > 0) {
      const timer = setInterval(() => {
        dispatch(deleteAlert({ key: alertItems[alertItems.length - 1].key }));
      }, alertItems[alertItems.length - 1].time);

      
      return () => clearInterval(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertItems]);

  const dispatch = useDispatch();

  return alertItems.map((item) => (
    <Alert
      severity={item.state}
      sx={alertStyle}
      onClose={() => {
        dispatch(deleteAlert({ key: item.key }));
      }}
      key={item.key}
    >
      {item.message}
      {item?.secondMessage !== null && (
        <>
          <br />
          <br />
          {item?.secondMessage}
        </>
      )}
    </Alert>
  ));
};

export default AlertCustomize;

/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import { ASKER, INST } from '../../context/roles';
import { statesApiProtertyName } from '../../context/ApiPropertyName';
import { setAlert } from '../../feature/Alert/AlertSlice';
import { addRequestList, addStats, addUserList } from '../../feature/Dasboard/DashboardSlice';
import { deleteLoader, setLoader } from '../../feature/Loader/LoaderSlice';
import { addUserInfo } from '../../feature/login/loginSlice';
import randomkey from '../../helpers/randomKey';
import SessionService from '../../Services/SessionService';
import alertMock from '../../_mock/alert';

const Reload = ({ type = ASKER(), finish = () => {} }) => {
  const dispatch = useDispatch();
  const LoaderKey = randomkey();

  React.useEffect(() => {
    dispatch(setLoader({ key: LoaderKey }));
    SessionService.Stats()
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          const states = { total: data.total, wait: 0, accept: 0, rejet: 0, correct: 0, finish: 0, incomplet: 0 };
          data.group.forEach((state) => {
            switch (state.status) {
              case statesApiProtertyName.wait:
                states.wait = state.count;
                break;

              case statesApiProtertyName.accept:
                states.accept = state.count;
                break;

              case statesApiProtertyName.incomplet:
                states.incomplet = state.count;
                break;

              case statesApiProtertyName.rejete:
                states.rejet = state.count;
                break;

              case statesApiProtertyName.correct:
                states.correct = state.count;
                break;

              case statesApiProtertyName.finish:
                states.finish = state.count;
                break;

              default:
                break;
            }
          });
          dispatch(addStats(states));
        }
        requestList();
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestList = React.useCallback(() => {
    SessionService.RequestList({ page: 1, perPage: 100 })
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          getUserInfo();
          dispatch(addRequestList(data));
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserInfo = React.useCallback(() => {
    if (type === ASKER()) {
      SessionService.UserInfo()
        .then((values) => {
          dispatch(deleteLoader({ key: LoaderKey }));
          const { status } = values;
          const { data } = values.data;
          const dataStatus = values.data.error;
          if ([404, 401, 500].includes(status)) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else if (dataStatus === true) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else {
            dispatch(addUserInfo({ email: data.email, managerName: data.manager_name, all: data }));
          }
          finish();
        })
        .catch((error) => {
          dispatch(deleteLoader({ key: LoaderKey }));
          if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
            if (error.response.data.error === true) {
              dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
            }
          } else {
            dispatch(
              setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
            );
          }
          finish();
        });
    } else if (type === INST()) {
      SessionService.AllUser()
        .then((values) => {
          const { status } = values;
          const { data } = values.data;
          const dataStatus = values.data.error;
          if ([404, 401, 500].includes(status)) {
            dispatch(deleteLoader({ key: LoaderKey }));
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else if (dataStatus === true) {
            dispatch(deleteLoader({ key: LoaderKey }));
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else {
            dispatch(addUserList(data));
            dispatch(deleteLoader({ key: LoaderKey }));
          }
          finish();
        })
        .catch((error) => {
          dispatch(deleteLoader({ key: LoaderKey }));
          if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
            if (error.response.data.error === true) {
              dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
            }
          } else {
            dispatch(
              setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
            );
          }
          finish();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Reload</div>;
};

export default Reload;

/* eslint-disable react/prop-types */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

function ConfirmAlertWithWhy(props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = (accept, values) => {
    setOpen(false);
    props.onClose(accept, values);
  };

  const initialValues = {
    description: '',
  };

  const validationSchema = yup.object({
    description: yup
      .string('Veuillez saisir les informations correctement.')
      .required("Ce champ est obligatoire pour que la demande de crédit sache ce qu'il faut modifier."),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => handleClick(values),
  });

  const handleClick = (values) => {
    handleClose(true, values);
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(false)} sx={{ zIndex: '99999999999999999' }}>
        <Box component={'form'} onSubmit={formik.handleSubmit}>
          <DialogTitle>{[undefined, null, ''].includes(props.title) ? "Confirmer l'action" : props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{props.message}</DialogContentText>
            <Box sx={{mt:'20px'}}>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel id="creation_date">Explication de la demande de modifications avant approbation</FormLabel>
                <TextField
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  type="description"
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    inputProps: {
                      style: {
                        width: '100%',
                        height: '100px',
                        borderSize: '2px',
                        borderRadius: '5px',
                      },
                    },
                  }}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} color="error" variant="contained">
              Annuler
            </Button>
            <Button type="submit" color="primary" autoFocus variant="contained">
              Confirmer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default ConfirmAlertWithWhy;

export const HomeRouteLink = () => '/';
export const LoginRouteLink = () => '/';
export const NotFoundRouteLink = () => '*'; // This is a catch-all router that will match any path that is not matched by the other routes.
export const DashboardRouteLink = () => '/';
export const AdminUserRouteLink = () => '/';
export const AdminInstRouteLink = () => '/inst';
export const AdminAkerRouteLink = () => '/demande';
export const UserRouteLink = () => '/';
export const InstCrudAdminLink = () => 'user-setting';
export const ChatRouteLink = () => '/messagerie';

/* eslint-disable react/prop-types */
import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';
import GenerateModalButton from './GenerateModalButton';

const CreateModal = ({ OpenButton = GenerateModalButton, children, ModalContent, ContentProps, MakeOpen = false }) => {
  const [open, setOpen] = React.useState(MakeOpen);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const CenterContent = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: { xs: '90vh', md: '80vh' },
    overflow: 'hidden',
    overflowY: 'scroll',
    width: { xs: '90vw', md: '80vw', lg: '75vw' },
  };

  const ContentButton = <>{children}</>;

  return (
    <div>
      <OpenButton handleOpen={handleOpen} content={ContentButton} />
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableScrollLock
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          zIndex: 10,
        }}
      >
        <Fade in={open}>
          <Box sx={CenterContent}>
            <ModalContent handleClose={handleClose} {...ContentProps} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default CreateModal;

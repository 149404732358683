/* eslint-disable react/prop-types */
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { fDate } from '../../utils/formatTime';
import { selectLogin } from '../../feature/login/loginSlice';
import Label from '../label/Label';
import { selectDashboard } from '../../feature/Dasboard/DashboardSlice';
import { ADMIN, ASKER, INST } from '../../context/roles';

const ShowDemandeInformatin = ({ DemandeHasBeenClickInfo, demande = false }) => {
  const userInfo = useSelector(selectLogin).userInfo.all;
  const { userList } = useSelector(selectDashboard);
  const [company, setCompany] = React.useState(null);
  const [document, setDoucument] = React.useState(null);
  const [equipement, setEquipement] = React.useState(null);
  const { role } = useSelector(selectLogin);

  //   React.useEffect(() => {
  //     if (role.name === ADMIN()) {
  //     }
  //   }, [role]);

  React.useEffect(() => {
    if (userInfo && DemandeHasBeenClickInfo && role.name === ASKER() && demande === true) {
      const companyInformation = [
        {
          title: "Nom legal de l'entreprise",
          content: userInfo.manager_name,
        },
        {
          title: 'Nom du manager',
          content: userInfo.tenant.legal_name,
        },
        {
          title: "Date officielle de creation de l'entreprise",
          content: fDate(userInfo.tenant.compagny_creation_date),
        },
        {
          title: "Votre entreprise a-t-elle un minimum de 3 années d'existence ?",
          content: ['oui', true].includes(DemandeHasBeenClickInfo.min_3_years_existance)
            ? "Confirmé par l'entreprise"
            : "Non confirmé par l'entreprise",
        },
        {
          title: 'Nom et prenom(s)',
          content: userInfo.tenant.legal_name,
        },
        {
          title: 'Localisation',
          content: userInfo.tenant.location,
        },
        {
          title: 'Email',
          content: userInfo.email,
        },
        {
          title: 'Mobile',
          content: userInfo.phone,
        },
        {
          title: 'Mobile secondaire',
          content: userInfo.phone2,
        },
        {
          title: "Secteur d'activites principale",
          content: userInfo.tenant.core_business_area,
        },
        {
          title: "secteur d'activites secondaire",
          content: userInfo.tenant.non_core_business_area,
        },
      ];
      const documentInformation = [
        {
          title: 'Photocopie de la pièce du gérant ou du chef d’entreprise (scan)',
          content: DemandeHasBeenClickInfo.managerID === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: 'Copie des statuts de la société',
          content:
            DemandeHasBeenClickInfo.compagny_statutes === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Registre de commerce authentifié par le tribunal du commerce (scan) ',
          content:
            DemandeHasBeenClickInfo.commercial_register === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: "  Déclaration d'existence fiscale DFE (scan)",
          content: DemandeHasBeenClickInfo.tax_declaration === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Plan de localisation Google Maps ',
          content: DemandeHasBeenClickInfo.g_map === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Copie d’une quittance CIE/SODECI (scan) ',
          content: DemandeHasBeenClickInfo.receipt === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Relevé d’identité bancaire (RIB ou IBAN) ',
          content: DemandeHasBeenClickInfo.rib_iban === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Relevés bancaires des 6 derniers mois (totaux mouvements et soldes/mois) ',
          content: DemandeHasBeenClickInfo.bank_statements === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title:
            ' États financiers visés par un cabinet comptable des 3 derniers exercices (liasse complète syscohada) ',
          content:
            DemandeHasBeenClickInfo.financial_statement === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Situation à mi-parcours ou Balance générale des comptes ',
          content: DemandeHasBeenClickInfo.general_balance === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: 'Prévisionnel (business plan)  ',
          content: DemandeHasBeenClickInfo.business_plan === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' États des marchés en cours d’exécution ',
          content:
            DemandeHasBeenClickInfo.in_execution_contracts === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Etat du parc auto et du parc machines en exploitation ',
          content: DemandeHasBeenClickInfo.machinery === true ? 'Document en possession' : 'Document non détenu',
        },
      ];
      const equipementInformation = [
        {
          title: 'Titre de la demande credit',
          content: DemandeHasBeenClickInfo.title,
        },

        {
          title: 'S’agit-il de matériel(s) roulant(s) ?',
          content: ['oui', true].includes(DemandeHasBeenClickInfo.rolling_stock)
            ? "Confirmé par l'entreprise"
            : "Non confirmé par l'entreprise",
        },
        {
          title: 'S’agit-il de matériel neuf',
          content: ['oui', true].includes(DemandeHasBeenClickInfo.new_equipment)
            ? "Confirmé par l'entreprise"
            : "Non confirmé par l'entreprise",
        },
        {
          title: 'Le fournisseur est-il local (présence en Cote d’Ivoire) ?',
          content: DemandeHasBeenClickInfo.local_provider,
        },
        {
          title: 'Le fournisseur est-il un professionnel représentant légalement la marque ?',
          content: DemandeHasBeenClickInfo.legal_provider,
        },
        {
          title: 'Description de la nature de(s) équipement(s)',
          content: DemandeHasBeenClickInfo.equipement_description,
        },
        {
          title: 'Un lien web de(s) équipement(s) (optionnel)',
          content: DemandeHasBeenClickInfo.equipement_web_link,
        },
        {
          title: 'Quelle est la durée de financement souhaitée (36 mois ou moins est la durée généralement admise)',
          content: DemandeHasBeenClickInfo.funding_period,
        },
        {
          title: 'Quel est le montant du financement sollicité ',
          content: DemandeHasBeenClickInfo.funding_amount,
        },
        {
          title: 'Quel est le montant de vos capitaux propres/fonds propres ?',
          content: DemandeHasBeenClickInfo.capital,
        },
        {
          title: 'Quel est votre total bilan de l’exercice dernier ?',
          content: DemandeHasBeenClickInfo.last_contract_balance,
        },
        {
          title: 'Avez vous déjà des encours de crédits avec des banques et institutions financières ?',
          content:
            DemandeHasBeenClickInfo.outstanding_loans === true
              ? "Confirmé par l'entreprise"
              : "Non confirmé par l'entreprise",
        },
        {
          title: 'Si oui, quel est le montant ?',
          content:
            DemandeHasBeenClickInfo.outstanding_loans_amount === null
              ? "Non confirmé par l'entreprise"
              : DemandeHasBeenClickInfo.outstanding_loans_amount,
        },
        {
          title: "Avez vous fait l’objet d'une cessation de paiement ou d'un règlement préventif ?",
          content:
            DemandeHasBeenClickInfo.default_to_pay === true
              ? "Confirmé par l'entreprise"
              : "Non confirmé par l'entreprise",
        },
      ];
      setCompany(companyInformation);
      setDoucument(documentInformation);
      setEquipement(equipementInformation);
    } else if (role.name === ADMIN() && demande === false) {
      const newUserList = userList.find((item) => item.id === DemandeHasBeenClickInfo.id);
      const companyInformation = [
        {
          title: "Nom legal de l'entreprise",
          content: newUserList.manager_name,
        },
        {
          title: 'Nom du manager',
          content: newUserList.tenant.legal_name,
        },
        {
          title: "Date officielle de creation de l'entreprise",
          content: fDate(newUserList.tenant.compagny_creation_date),
        },
        {
          title: "Votre entreprise a-t-elle un minimum de 3 années d'existence ?",
          content: ['oui', true].includes(newUserList.tenant.min_3_years_existance)
            ? "Confirmé par l'entreprise"
            : "Non confirmé par l'entreprise",
        },
        {
          title: 'Nom et prenom(s)',
          content: newUserList.tenant.legal_name,
        },
        {
          title: 'Localisation',
          content: newUserList.tenant.location,
        },
        {
          title: 'Email',
          content: newUserList.email,
        },
        {
          title: 'Mobile',
          content: newUserList.phone,
        },
        {
          title: 'Mobile secondaire',
          content: newUserList.phone2,
        },
        {
          title: "Secteur d'activites principale",
          content: newUserList.tenant.core_business_area,
        },
        {
          title: "secteur d'activites secondaire",
          content: newUserList.tenant.non_core_business_area,
        },
      ];
      setCompany(companyInformation);
    } else if ((demande === true && role.name === ADMIN()) || role.name === INST()) {
      const newUserList = userList.find((item) => item.tenant.id === DemandeHasBeenClickInfo.tenant_id);

      const companyInformation = [
        {
          title: "Nom legal de l'entreprise",
          content: newUserList.manager_name,
        },
        {
          title: 'Nom du manager',
          content: newUserList.tenant.legal_name,
        },
        {
          title: "Date officielle de creation de l'entreprise",
          content: fDate(newUserList.tenant.compagny_creation_date),
        },
        {
          title: "Votre entreprise a-t-elle un minimum de 3 années d'existence ?",
          content: ['oui', true].includes(newUserList.tenant.min_3_years_existance)
            ? "Confirmé par l'entreprise"
            : "Non confirmé par l'entreprise",
        },
        {
          title: 'Nom et prenom(s)',
          content: newUserList.tenant.legal_name,
        },
        {
          title: 'Localisation',
          content: newUserList.tenant.location,
        },
        {
          title: 'Email',
          content: newUserList.email,
        },
        {
          title: 'Mobile',
          content: newUserList.phone,
        },
        {
          title: 'Mobile secondaire',
          content: newUserList.phone2,
        },
        {
          title: "Secteur d'activites principale",
          content: newUserList.tenant.core_business_area,
        },
        {
          title: "secteur d'activites secondaire",
          content: newUserList.tenant.non_core_business_area,
        },
      ];
      const documentInformation = [
        {
          title: 'Photocopie de la pièce du gérant ou du chef d’entreprise (scan)',
          content: DemandeHasBeenClickInfo.managerID === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: 'Copie des statuts de la société',
          content:
            DemandeHasBeenClickInfo.compagny_statutes === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Registre de commerce authentifié par le tribunal du commerce (scan) ',
          content:
            DemandeHasBeenClickInfo.commercial_register === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: "  Déclaration d'existence fiscale DFE (scan)",
          content: DemandeHasBeenClickInfo.tax_declaration === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Plan de localisation Google Maps ',
          content: DemandeHasBeenClickInfo.g_map === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Copie d’une quittance CIE/SODECI (scan) ',
          content: DemandeHasBeenClickInfo.receipt === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Relevé d’identité bancaire (RIB ou IBAN) ',
          content: DemandeHasBeenClickInfo.rib_iban === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Relevés bancaires des 6 derniers mois (totaux mouvements et soldes/mois) ',
          content: DemandeHasBeenClickInfo.bank_statements === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title:
            ' États financiers visés par un cabinet comptable des 3 derniers exercices (liasse complète syscohada) ',
          content:
            DemandeHasBeenClickInfo.financial_statement === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Situation à mi-parcours ou Balance générale des comptes ',
          content: DemandeHasBeenClickInfo.general_balance === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: 'Prévisionnel (business plan)  ',
          content: DemandeHasBeenClickInfo.business_plan === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' États des marchés en cours d’exécution ',
          content:
            DemandeHasBeenClickInfo.in_execution_contracts === true ? 'Document en possession' : 'Document non détenu',
        },
        {
          title: ' Etat du parc auto et du parc machines en exploitation ',
          content: DemandeHasBeenClickInfo.machinery === true ? 'Document en possession' : 'Document non détenu',
        },
      ];
      const equipementInformation = [
        {
          title: 'Titre de la demande credit',
          content: DemandeHasBeenClickInfo.title,
        },

        {
          title: 'S’agit-il de matériel(s) roulant(s) ?',
          content: ['oui', true].includes(DemandeHasBeenClickInfo.rolling_stock)
            ? "Confirmé par l'entreprise"
            : "Non confirmé par l'entreprise",
        },
        {
          title: 'S’agit-il de matériel neuf',
          content: ['oui', true].includes(DemandeHasBeenClickInfo.new_equipment)
            ? "Confirmé par l'entreprise"
            : "Non confirmé par l'entreprise",
        },
        {
          title: 'Le fournisseur est-il local (présence en Cote d’Ivoire) ?',
          content: DemandeHasBeenClickInfo.local_provider,
        },
        {
          title: 'Le fournisseur est-il un professionnel représentant légalement la marque ?',
          content: DemandeHasBeenClickInfo.legal_provider,
        },
        {
          title: 'Description de la nature de(s) équipement(s)',
          content: DemandeHasBeenClickInfo.equipement_description,
        },
        {
          title: 'Un lien web de(s) équipement(s) (optionnel)',
          content: DemandeHasBeenClickInfo.equipement_web_link,
        },
        {
          title: 'Quelle est la durée de financement souhaitée (36 mois ou moins est la durée généralement admise)',
          content: DemandeHasBeenClickInfo.funding_period,
        },
        {
          title: 'Quel est le montant du financement sollicité ',
          content: DemandeHasBeenClickInfo.funding_amount,
        },
        {
          title: 'Quel est le montant de vos capitaux propres/fonds propres ?',
          content: DemandeHasBeenClickInfo.capital,
        },
        {
          title: 'Quel est votre total bilan de l’exercice dernier ?',
          content: DemandeHasBeenClickInfo.last_contract_balance,
        },
        {
          title: 'Avez vous déjà des encours de crédits avec des banques et institutions financières ?',
          content:
            DemandeHasBeenClickInfo.outstanding_loans === true
              ? "Confirmé par l'entreprise"
              : "Non confirmé par l'entreprise",
        },
        {
          title: 'Si oui, quel est le montant ?',
          content:
            DemandeHasBeenClickInfo.outstanding_loans_amount === null
              ? "Non confirmé par l'entreprise"
              : DemandeHasBeenClickInfo.outstanding_loans_amount,
        },
        {
          title: "Avez vous fait l’objet d'une cessation de paiement ou d'un règlement préventif ?",
          content:
            DemandeHasBeenClickInfo.default_to_pay === true
              ? "Confirmé par l'entreprise"
              : "Non confirmé par l'entreprise",
        },
      ];

      setCompany(companyInformation);
      setDoucument(documentInformation);
      setEquipement(equipementInformation);
    }
  }, [userInfo, DemandeHasBeenClickInfo, role, userList, demande]);

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 2, mt: 3 }}>
        Les informations
      </Typography>
      <Typography variant="h5" sx={{ mb: 5 }}>
        Toutes les informations relatives à cette demande de crédit.
        <span style={{ color: 'red' }}> *</span>
      </Typography>

      <Box>
        <Typography sx={{ fontWeight: 'bold' }} variant="h5" color="primary">
          Les information de l'entreprise.
        </Typography>
        <Stack
          sx={{ mt: '10px', mb: '30px' }}
          direction="row"
          flexWrap={'wrap'}
          rowGap="40px"
          columnGap={'30px'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
        >
          {company !== null && company.map((item) => <Items {...item} key={item.title} />)}
        </Stack>
      </Box>

      {demande === true && (
        <>
          <Box>
            <Typography sx={{ fontWeight: 'bold' }} variant="h5" color="primary">
              Les informations sur les documents disponibles auprès de l'entreprise
            </Typography>
            <Stack
              sx={{ mt: '10px', mb: '30px' }}
              direction="row"
              flexWrap={'wrap'}
              rowGap="40px"
              columnGap={'40px'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
            >
              {document !== null && document.map((item) => <Items {...item} key={item.title} />)}
            </Stack>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 'bold' }} variant="h5" color="primary">
              Informations sur le(s) équipement(s).
            </Typography>
            <Stack
              sx={{ mt: '10px', mb: '30px' }}
              direction="row"
              flexWrap={'wrap'}
              rowGap="20px"
              columnGap={'50px'}
              justifyContent={'space-between'}
              alignItems={'flex-start'}
            >
              {equipement !== null && equipement.map((item) => <Items {...item} key={item.title} />)}
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};

const Items = ({ title, content }) => (
  <Box>
    <Typography sx={{ fontStyle: 'italic' }}>{title}</Typography>
    <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
      <Label
        color={
          content === null ||
          ["Non confirmé par l'entreprise", 'Document non détenu', 'non', 'je ne sais pas'].includes(content)
            ? 'error'
            : 'success'
        }
      >
        {content === null ? '- - -' : content}
      </Label>
    </Typography>
  </Box>
);

export default ShowDemandeInformatin;

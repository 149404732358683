// routes
// theme
import { useDispatch } from 'react-redux';
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import Router from './Router/router';
import { login } from './feature/login/loginSlice';
import AlertCustomize from './feature/Alert/AlertCustomize';
import Loader from './feature/Loader/Loader';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  dispatch(login());
  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <AlertCustomize />
      <Loader/>
      <Router />
    </ThemeProvider>
  );
}

import { Backdrop } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { selectLoader } from './LoaderSlice';

const Loader = () => {
  const opens = useSelector(selectLoader);

  return (
    <>
      {opens.map((items) => (
        <Backdrop sx={{ color: '#fff', zIndex: 999900 }} open key={items.key}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ))}
    </>
  );
};

export default Loader;

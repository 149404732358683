/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import {
  Box,
  FormControl,
  FormLabel,
  Stack,
  Typography,
  TextField,
  Button,
  TextareaAutosize,
  Select,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import randomkey from '../../helpers/randomKey';
import { deleteLoader, setLoader } from '../../feature/Loader/LoaderSlice';
import SessionService from '../../Services/SessionService';
import { setAlert } from '../../feature/Alert/AlertSlice';
import alertMock from '../../_mock/alert';
import RealoadAdminInst from '../Reload/RealoadAdminInst';
import { selectDashboard } from '../../feature/Dasboard/DashboardSlice';

const CreateGroupe = ({ handleClose }) => {
  const dispatch = useDispatch();
  const loaderKey = randomkey();
  const [reload, setReload] = React.useState(false);
  const [currentList, setCurrentList] = React.useState(null);
  const { groupeList } = useSelector(selectDashboard);

  React.useEffect(() => {
    if (groupeList !== null) {
      const newArray = groupeList.map((item) => {
        return item.priority;
      });

      const ArrayWithChecking = [];
      for (let i = 1; i <= 10; i += 1) {
        const item = { id: i, status: newArray.includes(i) };
        ArrayWithChecking.push(item);
      }

      setCurrentList(ArrayWithChecking);
    }
  }, [groupeList]);

  const initialValues = {
    priority_level: '',
    description: '',
  };

  const validationSchema = yup.object({
    description: yup.string('Entrez votre description').required('Ce champs est obligatoire'),
    priority_level: yup.number().required('Ce champ est requis').positive().integer().min(1),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => handleClick(values),
  });

  const handleClick = (values) => {
    dispatch(setLoader({ key: loaderKey }));
    SessionService.CreateGroupe(values)
      .then((values) => {
        dispatch(deleteLoader({ key: loaderKey }));
        const errorStatus = values.data.error;
        const { data } = values.data;
        if (errorStatus === false) {
          dispatch(setAlert({ state: 'success', message: data, time: 15000 }));
          setReload(true);
          handleClose();
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      })
      .catch((error) => {
        dispatch(setLoader({ key: loaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
  };
  return (
    <Box sx={{ width: '100%' }}>
      {reload === true && <RealoadAdminInst />}
      <Typography variant="h4" sx={{ marginBottom: '10px' }}>
        Formulaire de création de groupe
      </Typography>
      <Box>
        <Typography variant="h6" sx={{ mb: 5 }}>
          Saisissez les informations nécessaires à la création d'un groupe d'établissements de crédit.
          <span style={{ color: 'red' }}> *</span>
        </Typography>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems="flex-start"
          sx={{ width: '100%', margin: '5vh auto auto auto ', flexWrap: 'wrap', rowGap: '20px' }}
          component={'form'}
          onSubmit={formik.handleSubmit}
        >
          {' '}
          <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
            <FormLabel id="priority_level">Veuillez saisir le niveau de priorité a partir de 1 .</FormLabel>
            <Select
              id="priority_level"
              label="Le groupe de l'institution "
              value={formik.values.priority_level}
              name="priority_level"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              required
            >
              {currentList !== null &&
                currentList.map((item, key) => (
                  <MenuItem value={item.id} disabled={item.status} key={key}>
                    {item.id}
                  </MenuItem>
                ))}
            </Select>
            {formik.touched.priority_level && Boolean(formik.errors.priority_level) ? (
              <div style={{ color: 'red' }}>{formik.errors.priority_level}</div>
            ) : null}
          </FormControl>
          <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
            <FormLabel id="creation_date">Veuillez fournir une description du groupe</FormLabel>
            <TextField
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              type="description"
              InputProps={{
                inputComponent: TextareaAutosize,
                inputProps: {
                  style: {
                    width: '100%',
                    height: '100px',
                    borderSize: '2px',
                    borderRadius: '5px',
                  },
                },
              }}
            />
          </FormControl>
          <Button variant="contained" sx={{ width: '100%' }} type="submit">
            Soumettre
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default CreateGroupe;

import { createSlice } from '@reduxjs/toolkit';
import TokenDecode from '../../helpers/TokenDecode';

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isAuthenticated: false,
    role: null,
    userId: null,
    userInfo: {
      companyName: '',
      email: '',
      managerName: '',
      image: '/assets/images/avatars/avatar_default.jpg',
      all: {},
    },
  },
  reducers: {
    login(state) {
      const token = localStorage.getItem('token');
      if (token) {
        state.isAuthenticated = true;
        const token = localStorage.getItem('token');
        const uuid = TokenDecode(token);
        state.role = uuid.role;
        state.userId = uuid.user_id;
      } else {
        state.isAuthenticated = false;
      }
    },
    addUser(state, action) {
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
    },
    addUserInfo(state, action) {
      action.payload.image = state.userInfo.image;
      state.userInfo = action.payload;
    },
    logout() {
      localStorage.clear();
      window.location.pathname = '/';
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  },
});

export const { login, addUser, logout, addUserInfo } = loginSlice.actions;

export const selectLogin = (state) => state.login;

export default loginSlice.reducer;

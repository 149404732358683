const alertMock = {
  errorServeur: `Désolé pour l'inconvénient rencontré. Il semble que nous rencontrons actuellement des problèmes techniques sur notre serveur de connexion. Nous travaillons actuellement à résoudre ce problème le plus rapidement possible.`,

  errorServeurSecond: `En attendant, nous vous recommandons de réessayer plus tard ou de contacter notre support technique pour obtenir de l'aide supplémentaire. Nous sommes désolés pour les inconvénients causés et nous vous remercions pour votre compréhension.`,

  successLogin:
    "Félicitations ! Vous êtes maintenant connecté à votre compte. Vous pouvez accéder à toutes les fonctionnalités de notre service. Nous espérons que vous passerez un bon moment ici. Si vous avez besoin d'aide, n'hésitez pas à contacter notre support.",

  documentChange: 'Vos modifications ont été enregistrées avec succès.',
  RequestUpdate: 'Félicitations, vous venez de modifier le statut de cette demande.',
  statsError:
    "Désolé pour l'inconvénient rencontré. Il semble que nous rencontrons actuellement des problèmes techniques sur notre serveur de connexion. Nous travaillons actuellement à résoudre ce problème le plus rapidement possible.",
};

export default alertMock;

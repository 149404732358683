const randomkey = () => {
  let randomString = '';
  const char = 'abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let i;

  for (i = 0; i < 30; i += 1) {
    randomString += char.charAt(Math.floor(Math.random() * char.length));
  }
  return randomString;
};

export default randomkey;

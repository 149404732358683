import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from './@dashboard/user';
// mock
import { selectDashboard } from '../feature/Dasboard/DashboardSlice';
import CreateModal from '../components/Modal/CreateModal';
import ShowDocuments from '../components/ShowDocuments/ShowDocuments';
import ShowDemandeInformatin from '../components/ShowInformation/ShowDemandeInformatin';
import ConfirmAlert from '../components/ConfirmAlert/ConfirmAlert';
import ConfirmAlertWithWhy from '../components/ConfirmAlert/ConfirmAlertWithWhy';
import SessionService from '../Services/SessionService';
import { setAlert } from '../feature/Alert/AlertSlice';
import alertMock from '../_mock/alert';
import { deleteLoader, setLoader } from '../feature/Loader/LoaderSlice';
import randomkey from '../helpers/randomKey';
import Reload from '../components/Reload/Reload';
import { INST, SUBINST } from '../context/roles';
import CreateUnderAdmin from '../components/CreateUnderAdmin/CreateUnderAdmin';
import { selectLogin } from '../feature/login/loginSlice';
import { statesApiProtertyName } from '../context/ApiPropertyName';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Titre', alignRight: false },
  { id: 'eligibility', label: 'Mes documents', alignRight: false },
  { id: 'eligibility', label: 'Eligibilité', alignRight: false },
  { id: 'status', label: 'Etat de la demande', alignRight: false },
  { id: '', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function InstTable() {
  const { requestList } = useSelector(selectDashboard);
  const [USERLIST, SETUSERLIST] = useState([]);
  const [DemandeHasBeenClickInfo, setDemandeHasBeenClickInfo] = useState({});
  const dispatch = useDispatch();
  const LoaderKey = randomkey();
  const { role } = useSelector(selectLogin);

  useEffect(() => {
    if (requestList !== null) {
      SETUSERLIST(requestList);
    }
  }, [requestList]);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('status');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [accept, setAccept] = useState(false);
  const [rejet, setRejet] = useState(false);
  const [update, setUpdate] = useState(false);
  const [reload, setReload] = useState(false);

  const Api = (body) => {
    dispatch(setLoader({ key: LoaderKey }));
    SessionService.UpdateRequest(body)
      .then((values) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        const { status } = values;
        // const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          dispatch(setAlert({ state: 'success', message: alertMock.RequestUpdate }));
          setReload(true);
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
  };

  const handleActionAccept = (state) => {
    if (state) {
      const body = {
        lease_uuid: DemandeHasBeenClickInfo.lease_uuid,
        response: 1,
      };
      Api(body);
    }
  };

  const handleActionRejet = (state) => {
    if (state) {
      const body = {
        lease_uuid: DemandeHasBeenClickInfo.lease_uuid,
        response: -1,
      };
      Api(body);
    }
  };

  const handleActionUpdate = (state, data) => {
    if (state) {
      const body = {
        lease_uuid: DemandeHasBeenClickInfo.lease_uuid,
        response: 0,
        content: data.description,
      };
      Api(body);
    }
  };

  const handleOpenMenu = (event, row) => {
    setDemandeHasBeenClickInfo(row);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      {reload && <Reload type={INST()} finish={() => setReload(false)} />}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} flexWrap="wrap">
          <Typography variant="h4" gutterBottom>
            les institutions de crédit-bail
          </Typography>
          {role.name !== SUBINST() && (
            <Stack flexDirection={'row'} columnGap="10px">
              <CreateModal ModalContent={CreateUnderAdmin}>
                <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                  Créer un sous-administrateur
                </Button>
              </CreateModal>
            </Stack>
          )}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // eslint-disable-next-line camelcase
                    const { id, title, status, eligibility } = row;
                    const selectedUser = selected.indexOf(title) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, title)} /> */}
                        </TableCell>

                        <TableCell>
                          <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
                            {/*  eslint-disable-next-line camelcase */}
                            {title}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          {["besoin d'un cabinet", 'éligible'].includes(eligibility) ? (
                            <CreateModal
                              ModalContent={ShowDocuments}
                              ContentProps={{ data: row.financial_statements, leasing: row.id }}
                            >
                              <Button variant="contained" color="info">
                                Les Documents
                              </Button>
                            </CreateModal>
                          ) : (
                            <Button variant="contained" disabled color="info">
                              Les Documents
                            </Button>
                          )}
                        </TableCell>

                        {/* <TableCell align="left">{role}</TableCell> */}

                        <TableCell align="left">
                          <Label
                            color={["besoin d'un cabinet", 'éligible'].includes(eligibility) ? 'success' : 'error'}
                          >
                            {eligibility}
                          </Label>
                        </TableCell>

                        <TableCell align="left">
                          <Label color={['incomplet', 'rejeté'].includes(status) ? 'error' : 'success'}>{status}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Non trouvé
                          </Typography>

                          <Typography variant="body2">
                            Aucun résultat trouvé pour &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ zIndex: 9 }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <CreateModal ModalContent={ShowDemandeInformatin} ContentProps={{ DemandeHasBeenClickInfo, demande: true }}>
          <MenuItem>
            <Iconify icon={'eva:eye-fill'} sx={{ mr: 2 }} />
            Voir
          </MenuItem>
        </CreateModal>
        {DemandeHasBeenClickInfo.status === statesApiProtertyName.wait && (
          <>
            <MenuItem
              sx={{ color: 'success.main' }}
              onClick={() => {
                setAccept(true);
                setOpen(null);
              }}
              disabled={role.name === SUBINST()}
            >
              <Iconify icon={'eva:checkmark-circle-2-outline'} sx={{ mr: 2 }} />
              Accepter
            </MenuItem>

            <MenuItem
              sx={{ color: 'warning.main' }}
              onClick={() => {
                setUpdate(true);
                setOpen(null);
              }}
              disabled={role.name === SUBINST()}
            >
              <Tooltip title="Avec ce bouton vous pourrez demander une modification du projet afin d'accepter">
                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              </Tooltip>
              A Modifier
            </MenuItem>

            <MenuItem
              sx={{ color: 'error.main' }}
              onClick={() => {
                setRejet(true);
                setOpen(null);
              }}
              disabled={role.name === SUBINST()}
            >
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Rejeter
            </MenuItem>
          </>
        )}
      </Popover>

      {accept === true && (
        <ConfirmAlert
          onClose={(state) => {
            setAccept(false);
            handleActionAccept(state);
          }}
          message={
            'Avant de poursuivre, nous devons vous demander : voulez-vous vraiment donner suite à cette demande de financement ? Votre approbation est nécessaire pour que nous puissions avancer.'
          }
          title={"Confirmation de l'acceptation du financement"}
        />
      )}

      {rejet === true && (
        <ConfirmAlert
          onClose={(state) => {
            setRejet(false);
            handleActionRejet(state);
          }}
          message={
            'Avant de poursuivre, nous devons vous demander : êtes-vous sûr de vouloir refuser cette demande de financement ?'
          }
          title={'Confirmation de refus '}
        />
      )}

      {update === true && (
        <ConfirmAlertWithWhy
          title={'Confirmation de la demande de modification avant approbation.'}
          message="Avant de poursuivre, nous devons vous demander : voulez-vous vraiment donner suite à cette demande de modification ? Votre approbation est nécessaire pour que nous puissions aller de l'avant."
          onClose={(state, data) => {
            setUpdate(false);
            handleActionUpdate(state, data);
          }}
        />
      )}
    </>
  );
}

/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const StepperControle = (props) => {
  const handleNext = React.useCallback(
    (event) => {
      props.handleSubmit();
      props.handleNext(event);
    },
    [props]
  );

  return (
    <Box
      sx={{ display: props.activeStep === props.step ? 'flex' : 'none', flexDirection: 'row', pt: 2, width: '100%' }}
    >
      <Button
        color="inherit"
        variant="contained"
        disabled={props.activeStep === 0}
        onClick={props.handleBack}
        sx={{ mr: 1 }}
      >
        Précédent
      </Button>
      <Box sx={{ flex: '1 1 auto' }} />
      <Button variant="contained" onClick={handleNext} disabled={props.next}>
        {props.activeStep === props.steps.length - 1 ? 'Finir' : 'Suivant'}
      </Button>
    </Box>
  );
};

export default StepperControle;

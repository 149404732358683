/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const steps = ['Sélectionne les paramètres', 'Saisissez les informations', 'Télécharger des documents'];

export default function NewDemande() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [formData, setFormData] = React.useState([]);
  const [leasing, setLeasing] = React.useState(null);

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleAddStepOne = React.useCallback((newValue) => {
    setFormData(newValue);
  }, []);

  const setLeasingId = React.useCallback((leasingId) => {
    setLeasing(leasingId);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" sx={{ marginBottom: '10px' }}>
        Formulaire de demande de nouveau crédit.
      </Typography>
      <Stepper
        activeStep={activeStep}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          rowGap: '20px',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>vous venez de créer une nouvelle demande de crédit.</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>faire une nouvelle demande</Button>
          </Box>
        </>
      ) : (
        <Box>
          <StepOne
            activeStep={activeStep}
            steps={steps}
            handleNext={handleNext}
            handleBack={handleBack}
            handleReset={handleReset}
            handleAddStepOne={handleAddStepOne}
          />

          <StepTwo
            activeStep={activeStep}
            steps={steps}
            handleNext={handleNext}
            handleBack={handleBack}
            handleReset={handleReset}
            stepOneData={formData}
            setLeasingId={setLeasingId}
          />

          <StepThree
            activeStep={activeStep}
            steps={steps}
            handleNext={handleNext}
            handleBack={handleBack}
            handleReset={handleReset}
            leasingId={leasing}
          />
        </Box>
      )}
    </Box>
  );
}

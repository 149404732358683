import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  // default state
  //   {
  //      key: "loading..."
  //   },
];

const LoaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoader(state, action) {
      state.push({ ...action.payload });
    },
    deleteLoader(state, action) {
      const element = state.find((element) => element.key === action.payload.key);

      const index = state.indexOf(element);
      if (index > -1) {
        state.splice(index, 1);
      }
    },
  },
});

export const { setLoader, deleteLoader } = LoaderSlice.actions;
export const selectLoader = (state) => state.loader;

export default LoaderSlice.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// @mui
// components
import { useDispatch } from 'react-redux';
import SessionService from '../../Services/SessionService';
import { setAlert } from '../../feature/Alert/AlertSlice';
import alertMock from '../../_mock/alert';
import { addGroupeList, addInstlist, addStats } from '../../feature/Dasboard/DashboardSlice';
import { deleteLoader, setLoader } from '../../feature/Loader/LoaderSlice';
import randomkey from '../../helpers/randomKey';
import { INST } from '../../context/roles';

const RealoadAdminInst = () => {
  const dispatch = useDispatch();
  const LoaderKey = randomkey();

  React.useEffect(() => {
    dispatch(setLoader({ key: LoaderKey }));
    SessionService.Statics()
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          dispatch(deleteLoader({ key: LoaderKey }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          dispatch(deleteLoader({ key: LoaderKey }));
        } else if (data.length === 0) {
          const states = { total: 0 };
          dispatch(addStats(states));
          dispatch(deleteLoader({ key: LoaderKey }));
        } else {
          const states = { total: 0 };
          const total = data.find((item) => item.role === INST());
          states.total = total?.count !== undefined ? total?.count : 0;
          dispatch(addStats(states));
          requestList();
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestList = React.useCallback(() => {
    SessionService.ListInstitution()
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(deleteLoader({ key: LoaderKey }));
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(deleteLoader({ key: LoaderKey }));
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          dispatch(addInstlist(data));
          GroupeList();
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   const getUserInfo = React.useCallback(() => {}, []);
  const GroupeList = React.useCallback(() => {
    SessionService.ListGroupe()
      .then((values) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          dispatch(deleteLoader({ key: LoaderKey }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          dispatch(deleteLoader({ key: LoaderKey }));
        } else {
          dispatch(addGroupeList(data));
          dispatch(deleteLoader({ key: LoaderKey }));
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
};

export default RealoadAdminInst;

/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { ADMIN, ASKER, SUBINST } from '../context/roles';
import { selectLogin } from '../feature/login/loginSlice';

const Midelware = ({ admin, user, inst, subInst }) => {
  const { role } = useSelector(selectLogin);
  const [Comp, setComp] = React.useState();
  React.useEffect(() => {
    if (role !== null) {
      if (role.name === ADMIN()) {
        setComp(admin);
      } else if (role.name === ASKER()) {
        setComp(user);
      } else if (role.name === SUBINST()) {
        setComp(subInst);
      } else if (role.name) {
        setComp(inst);
      }
    }
  }, [admin, inst, role, subInst, user]);
  return <>{Comp}</>;
};

export default Midelware;

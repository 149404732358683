const { createSlice } = require('@reduxjs/toolkit');

const reloadSlice = createSlice({
  name: 'reload',
  initialState: { adminInstitution: true, adminDemande: true, adminUser: true, inst: true },
  reducers: {
    offReload(state, action) {
      action.payload.forEach((item) => {
        state[item] = false;
      });
    },
  },
});

export const { offReload } = reloadSlice.actions;
export const selectReload = (state) => state.reload;

export default reloadSlice.reducer;

// component
import {
  AdminAkerRouteLink,
  AdminInstRouteLink,
  AdminUserRouteLink,
  InstCrudAdminLink,
  UserRouteLink,
} from '../../../Router/routes';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const user = [
  {
    title: 'dashboard',
    path: UserRouteLink(),
    icon: icon('ic_analytics'),
  },
];

const inst = [
  {
    title: 'dashboard',
    path: UserRouteLink(),
    icon: icon('ic_analytics'),
  },
  {
    title: 'utilisateur',
    path: InstCrudAdminLink(),
    icon: icon('ic_user'),
  },
];

const admin = [
  {
    title: 'utilisateur',
    path: AdminUserRouteLink(),
    icon: icon('ic_user'),
  },
  {
    title: 'instutition de crédit-bail',
    path: AdminInstRouteLink(),
    icon: icon('ic_analytics'),
  },
  {
    title: 'Demandes',
    path: AdminAkerRouteLink(),
    icon: icon('ic_cart'),
  },
];

export { user, admin, inst };

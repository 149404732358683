/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  FormControl,
  FormLabel,
  Select,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from './@dashboard/user';
// mock
import { selectDashboard } from '../feature/Dasboard/DashboardSlice';
import CreateModal from '../components/Modal/CreateModal';
import NewInstitution from '../components/NewInstitution/NewInstitution';
import CreateGroupe from '../components/Groupe/CreateGroupe';
import Label from '../components/label/Label';
import randomkey from '../helpers/randomKey';
import RealoadAdminInst from '../components/Reload/RealoadAdminInst';
import SessionService from '../Services/SessionService';
import { deleteLoader, setLoader } from '../feature/Loader/LoaderSlice';
import { setAlert } from '../feature/Alert/AlertSlice';
import alertMock from '../_mock/alert';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'manager_name', label: 'Manager', alignRight: false },
  { id: 'legal_name', label: 'Entreprise', alignRight: false },
  { id: 'leasing_institution_group_id', label: 'Priorité', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'core_business_area', label: "domaine d'activité principal", alignRight: false },
  { id: '', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AdminInstTable() {
  const { InstList } = useSelector(selectDashboard);
  const [USERLIST, SETUSERLIST] = useState([]);
  const { groupeList } = useSelector(selectDashboard);

  useEffect(() => {
    if (InstList !== null) {
      SETUSERLIST(InstList);
    }
  }, [InstList]);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [InstInfo, setInstInfo] = useState({});

  const handleOpenMenu = (event, row) => {
    setInstInfo(row);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} flexWrap="wrap">
          <Typography variant="h4" gutterBottom>
            les institutions de crédit-bail
          </Typography>
          <Stack flexDirection={'row'} columnGap="10px">
            <CreateModal ModalContent={NewInstitution}>
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                Créer une Institution
              </Button>
            </CreateModal>
            <CreateModal ModalContent={CreateGroupe}>
              <Button variant="contained" color={'info'} startIcon={<Iconify icon="eva:cube-fill" />}>
                Créer un groupe
              </Button>
            </CreateModal>
          </Stack>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // eslint-disable-next-line camelcase
                    const { id, manager_name, email, phone, tenant } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell padding="checkbox">
                          {/* <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, title)} /> */}
                        </TableCell>

                        <TableCell>
                          <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
                            {/*  eslint-disable-next-line camelcase */}
                            {manager_name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{tenant?.legal_name}</TableCell>

                        <TableCell align="left">
                          <Label color="success">
                            {groupeList !== null &&
                              groupeList.find((item) => item.id === tenant?.leasing_institution_group_id)?.priority}
                          </Label>
                        </TableCell>

                        <TableCell align="left">{email}</TableCell>

                        <TableCell align="left">{phone}</TableCell>


                        <TableCell align="left">{tenant?.core_business_area}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Non trouvé
                          </Typography>

                          <Typography variant="body2">
                            Aucun résultat trouvé pour &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ zIndex: 9 }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <CreateModal ModalContent={UpdatePriority} ContentProps={{ InstInfo }}>
          <MenuItem sx={{ color: 'info.main' }}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit Priorite
          </MenuItem>
        </CreateModal>
      </Popover>
    </>
  );
}

const UpdatePriority = ({ InstInfo, handleClose }) => {
  const dispatch = useDispatch();
  const loaderKey = randomkey();
  const { groupeList } = useSelector(selectDashboard);
  const [currentList, setCurrentList] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (groupeList !== null) {
      const newArray = groupeList.map((item) => item.priority);
      setCurrentList(newArray);
    }
  }, [groupeList]);

  const initialValues = {
    priority_level: groupeList.find((item) => item.id === InstInfo.tenant.leasing_institution_group_id).priority,
  };

  const validationSchema = yup.object({
    priority_level: yup.number().required('Ce champ est requis').positive().integer().min(1),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => handleClick(values),
  });

  const handleClick = (values) => {
    const body = {
      institution_tenant_id: InstInfo.id,
      group_id: groupeList.find((item) => item.priority === values.priority_level).id,
    };
    dispatch(setLoader({ key: loaderKey }));
    SessionService.UpdateGroupe(body)
      .then((values) => {
        dispatch(deleteLoader({ key: loaderKey }));
        const errorStatus = values.data.error;
        const { data } = values.data;
        if (errorStatus === false) {
          dispatch(setAlert({ state: 'success', message: data, time: 15000 }));
          setReload(true);
          handleClose();
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      })
      .catch((error) => {
        dispatch(setLoader({ key: loaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
  };
  return (
    <Box>
      {reload === true && <RealoadAdminInst />}
      <Typography variant="h4" sx={{ mb: 2, mt: 3 }}>
        La Priorité
      </Typography>
      <Typography variant="h5" sx={{ mb: 5 }}>
        Vous pouvez modifier la priorité de cet établissement de crédit-bail.
        <span style={{ color: 'red' }}> *</span>
      </Typography>
      <Box component={'form'} onSubmit={formik.handleSubmit}>
        <FormControl sx={{ width: '100%', mb: '10px' }}>
          <FormLabel id="priority_level">Veuillez saisir le niveau de priorité .</FormLabel>
          <Select
            id="priority_level"
            label="Le groupe de l'institution "
            value={formik.values.priority_level}
            name="priority_level"
            onChange={(e) => {
              formik.handleChange(e);
            }}
            required
          >
            {currentList !== null &&
              currentList.map((item, key) => (
                <MenuItem value={item} key={key}>
                  {item}
                </MenuItem>
              ))}
          </Select>
          {formik.touched.priority_level && Boolean(formik.errors.priority_level) ? (
            <div style={{ color: 'red' }}>{formik.errors.priority_level}</div>
          ) : null}
        </FormControl>
        <Button variant="contained" sx={{ width: '100%' }} type="submit">
          Soumettre
        </Button>
      </Box>
    </Box>
  );
};

const ServiceRoutes = {
  auth: {
    signIn: '/auth/sign-in/',
    userInfo: '/auth/retrive/user',
    getAllUser: '/auth/list/user',
    getAdmin: '/auth/retrive/admin',
  },
  dashboard: {
    stats: '/lease/stats',
    requestList: (page = 1, perPage = 10, status = null, eligibility = null) =>
      `/lease/list/?page=${page}&perPage=${perPage}${status === null ? '' : '&status='}${
        status === null ? '' : status
      }${eligibility === null ? '' : '&eligibility='}${eligibility === null ? '' : eligibility}`,
  },
  admin: {
    listInstitution: '/auth/list/institution',
    createInsitution: '/auth/sign-up/leasing-institution',
    get: '/auth/statistics',
  },
  request: {
    createRequest: '/lease/request/test-eligibility',
    createAssistance: '/lease/request/assistance',
    AddDocument: '/lease/request/financial-statement',
    updateRequest: '/lease/request/leasing-response',
  },
  groupe: {
    createGroupe: '/leasing-institution/group/new',
    listGroupe: '/leasing-institution/group/list',
    updateGroupe: '/leasing-institution/group/move-institution',
  },
  inst: {
    createUnderAdmin: '/auth/sign-up/leasing-institution-staff',
  },
  message: {
    createMessage: '/message/post',
    getMessage: (id) => `/message/read/chat/${id}`,
  },
};

export default ServiceRoutes;

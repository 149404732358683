import React from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import UserPage from './UserPage';
import SessionService from '../Services/SessionService';
import { setAlert } from '../feature/Alert/AlertSlice';
import alertMock from '../_mock/alert';
import { statesApiProtertyName } from '../context/ApiPropertyName';
import { addRequestList, addStats, selectDashboard } from '../feature/Dasboard/DashboardSlice';
import { deleteLoader, setLoader } from '../feature/Loader/LoaderSlice';
import randomkey from '../helpers/randomKey';
import { addUserInfo } from '../feature/login/loginSlice';
import { addNotification } from '../feature/Notification/notificationSlice';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const dispatch = useDispatch();
  const { stats } = useSelector(selectDashboard);
  const LoaderKey = randomkey();

  React.useEffect(() => {
    dispatch(setLoader({ key: LoaderKey }));
    SessionService.Stats()
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const { notifications } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          const states = { total: data.total, wait: 0, accept: 0, rejet: 0, correct: 0, finish: 0, incomplet: 0 };
          data.group.forEach((state) => {
            switch (state.status) {
              case statesApiProtertyName.wait:
                states.wait = state.count;
                break;

              case statesApiProtertyName.accept:
                states.accept = state.count;
                break;

              case statesApiProtertyName.incomplet:
                states.incomplet = state.count;
                break;

              case statesApiProtertyName.rejet:
                states.rejet = state.count;
                break;

              case statesApiProtertyName.correct:
                states.correct = state.count;
                break;

              case statesApiProtertyName.finish:
                states.finish = state.count;
                break;

              default:
                break;
            }
          });
          dispatch(addStats(states));
          dispatch(addNotification({ notification: notifications }));
        }
        requestList();
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestList = React.useCallback(() => {
    SessionService.RequestList({ page: 1, perPage: 100 })
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          getUserInfo();
          dispatch(addRequestList(data));
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserInfo = React.useCallback(() => {
    SessionService.UserInfo()
      .then((values) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          dispatch(addUserInfo({ email: data.email, managerName: data.manager_name, all: data }));
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid>
        <Helmet>
          <title> Fasto Tableaux de bord</title>
        </Helmet>

        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Bonjour, bienvenue
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes effectuées"
                color="info"
                total={stats.total === 0 ? '0' : stats.total}
                icon={'ant-design:audit-outlined'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes en attentes de réponse"
                total={stats.wait === 0 ? '0' : stats.wait}
                color="warning"
                icon={'ant-design:history-outlined'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes en attentes de correction"
                total={stats.correct === 0 ? '0' : stats.correct}
                color="warning"
                icon={'ant-design:history-outlined'}
                out
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes acceptées"
                total={stats.accept === 0 ? '0' : stats.accept}
                color="success"
                icon={'ant-design:trophy-filled'}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes complétées"
                total={stats.rejet === 0 ? '0' : stats.rejet}
                color="success"
                out
                icon={'ant-design:issues-close-outlined'}
              />
            </Grid> */}

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes Incomplet"
                total={stats.incomplet === 0 ? '0' : stats.incomplet}
                color="error"
                icon={'ant-design:issues-close-outlined'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes rejetées"
                total={stats.rejet === 0 ? '0' : stats.rejet}
                color="error"
                out
                icon={'ant-design:file-excel-outlined'}
              />
            </Grid>

            {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
          </Grid>
        </Container>
      </Grid>
      <Grid sx={{ marginTop: '50px' }}>
        <UserPage />
      </Grid>
    </>
  );
}

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  stats: {
    total: 0,
    wait: 0,
    accept: 0,
    rejet: 0,
    correct: 0,
    incomplet: 0,
  },
  user: {
    total: 0,
  },
  demande: {
    total: 0,
    wait: 0,
    accept: 0,
    rejet: 0,
    correct: 0,
    incomplet: 0,
  },
  requestList: null,
  InstList: null,
  groupeList: null,
  userList: null,
  demandeList: null,
};

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    addStats(state, action) {
      state.stats = action.payload;
    },
    addStatsUser(state, action) {
      state.user = action.payload;
    },
    addRequestList(state, action) {
      state.requestList = action.payload;
    },
    addInstlist(state, action) {
      state.InstList = action.payload;
    },
    addGroupeList(state, action) {
      state.groupeList = action.payload;
    },
    addUserList(state, action) {
      state.userList = action.payload;
    },
    addDemandeList(state, action) {
      state.demandeList = action.payload;
    },
    addDemandeState(state, action) {
      state.demande = action.payload;
    },
  },
});

export const {
  addStats,
  addRequestList,
  addDemandeList,
  addInstlist,
  addGroupeList,
  addStatsUser,
  addUserList,
  addDemandeState,
} = DashboardSlice.actions;
export const selectDashboard = (state) => state.dashboard;

export default DashboardSlice.reducer;

/* eslint-disable import/no-unresolved */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Chat from 'src/components/Chat/Chat';
import { ADMIN, ASKER, INST, SUBINST } from 'src/context/roles';
import { selectLogin } from 'src/feature/login/loginSlice';
import Midelware from 'src/helpers/Midelware';
import RequireAuth from 'src/helpers/RequireAuth';
import DashboardLayout from 'src/layouts/dashboard/DashboardLayout';
import AdminAsker from 'src/pages/AdminAsker';
import AdminInst from 'src/pages/AdminInst';
import AdminUser from 'src/pages/AdminUser';
import DashboardAppPage from 'src/pages/DashboardAppPage';
import InstPages from 'src/pages/InstPages';
import LoginPage from 'src/pages/LoginPage';
import Page404 from 'src/pages/Page404';
import {
  AdminAkerRouteLink,
  AdminInstRouteLink,
  ChatRouteLink,
  DashboardRouteLink,
  LoginRouteLink,
  NotFoundRouteLink,
} from './routes';

const Router = () => {
  const LoginState = useSelector(selectLogin);
  return (
    <Routes>
      <Route>
        <Route path={NotFoundRouteLink()} element={<Page404 />} />
        {LoginState.isAuthenticated === false && <Route path={LoginRouteLink()} element={<LoginPage />} />}

        {LoginState.isAuthenticated === true && (
          <>
            <Route element={<DashboardLayout />}>
              <Route
                path={DashboardRouteLink()}
                element={<Midelware admin={<AdminUser />} inst={<InstPages />} user={<DashboardAppPage />} subInst={<InstPages/>} />}
                index
              />

              {/* ADMIN ROUTE */}

              <Route
                path={AdminInstRouteLink()}
                element={
                  <RequireAuth allowedRoles={ADMIN}>
                    <AdminInst />
                  </RequireAuth>
                }
              />

              <Route
                path={AdminAkerRouteLink()}
                element={
                  <RequireAuth allowedRoles={ADMIN}>
                    <AdminAsker />
                  </RequireAuth>
                }
              />

              {/* <Route
                path={InstCrudAdminLink()}
                element={
                  <RequireAuth allowedRoles={INST}>
                    <InstUser />
                  </RequireAuth>
                }
              /> */}

              <Route
                path={ChatRouteLink()}
                element={
                  <RequireAuth allowedRoles={[ADMIN(), ASKER(), INST(), SUBINST()]}>
                    <Chat />
                  </RequireAuth>
                }
              />
            </Route>
          </>
        )}
      </Route>
    </Routes>
  );
};

export default Router;

import React from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import SessionService from '../Services/SessionService';
import { setAlert } from '../feature/Alert/AlertSlice';
import alertMock from '../_mock/alert';
import { statesApiProtertyName } from '../context/ApiPropertyName';
import { addRequestList, addStats, addUserList, selectDashboard } from '../feature/Dasboard/DashboardSlice';
import { deleteLoader, setLoader } from '../feature/Loader/LoaderSlice';
import randomkey from '../helpers/randomKey';
import InstTable from '../sections/InstTable';
import { offReload, selectReload } from '../feature/reload/reloadSlice';
import { addNotification } from '../feature/Notification/notificationSlice';

// ----------------------------------------------------------------------

export default function InstPages() {
  const dispatch = useDispatch();
  const { stats } = useSelector(selectDashboard);
  const LoaderKey = randomkey();
  const { inst } = useSelector(selectReload);

  React.useEffect(() => {
    if (inst) {
      dispatch(setLoader({ key: LoaderKey }));
      SessionService.Stats()
        .then((values) => {
          const { status } = values;
          const { data } = values.data;
          const { notifications } = values.data;
          const dataStatus = values.data.error;
          if ([404, 401, 500].includes(status)) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else if (dataStatus === true) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else {
            const states = { total: data.total, wait: 0, accept: 0, rejet: 0, correct: 0, finish: 0, incomplet: 0 };
            data.group.forEach((state) => {
              switch (state.status) {
                case statesApiProtertyName.wait:
                  states.wait = state.count;
                  break;

                case statesApiProtertyName.accept:
                  states.accept = state.count;
                  break;

                case statesApiProtertyName.incomplet:
                  states.incomplet = state.count;
                  break;

                case statesApiProtertyName.rejete:
                  states.rejet = state.count;
                  break;

                case statesApiProtertyName.correct:
                  states.correct = state.count;
                  break;

                case statesApiProtertyName.termine:
                  states.finish = state.count;
                  break;

                default:
                  break;
              }
            });
            dispatch(addStats(states));
          }
          requestList();
          dispatch(addNotification({ notification: notifications }));
        })
        .catch((error) => {
          dispatch(deleteLoader({ key: LoaderKey }));
          if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
            if (error.response.data.error === true) {
              dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
            }
          } else {
            dispatch(
              setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
            );
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inst]);

  const requestList = React.useCallback(() => {
    SessionService.RequestList({ page: 1, perPage: 100 })
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          dispatch(deleteLoader({ key: LoaderKey }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          dispatch(deleteLoader({ key: LoaderKey }));
        } else {
          dispatch(addRequestList(data));
          getUser();
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = React.useCallback(() => {
    SessionService.AllUser()
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(deleteLoader({ key: LoaderKey }));
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(deleteLoader({ key: LoaderKey }));
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          dispatch(addUserList(data));
          dispatch(deleteLoader({ key: LoaderKey }));
          dispatch(offReload(['inst']));
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid>
        <Helmet>
          <title> Fasto </title>
        </Helmet>

        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Bonjour, bienvenue
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes reçues"
                color="info"
                total={stats.total === 0 ? '0' : stats.total}
                icon={'ant-design:audit-outlined'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes en attente de réponse"
                total={stats.wait === 0 ? '0' : stats.wait}
                color="warning"
                icon={'ant-design:history-outlined'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes en attentes de correction"
                total={stats.correct === 0 ? '0' : stats.correct}
                color="warning"
                icon={'ant-design:history-outlined'}
                out
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes acceptées"
                total={stats.accept === 0 ? '0' : stats.accept}
                color="success"
                icon={'ant-design:check-outlined'}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes crédit accordées"
                total={stats.finish === 0 ? '0' : stats.finish}
                color="success"
                icon={'ant-design:trophy-filled'}
                out
              />
            </Grid> */}

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes incomplet"
                total={stats.incomplet === 0 ? '0' : stats.incomplet}
                color="error"
                icon={'ant-design:issues-close-outlined'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total des demandes rejetées"
                total={stats.rejet === 0 ? '0' : stats.rejet}
                color="error"
                out
                icon={'ant-design:file-excel-outlined'}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid sx={{ marginTop: '50px' }}>
        <InstTable />
      </Grid>
    </>
  );
}

/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLogin } from '../feature/login/loginSlice';
import Page404 from '../pages/Page404';

// eslint-disable-next-line react/prop-types
const RequireAuth = ({ allowedRoles, children }) => {
  const loginState = useSelector(selectLogin);
  const [role, setRole] = useState();
  useEffect(() => {
    if (loginState.isAuthenticated) {
      const userRole = loginState.role.name;
      setRole(userRole);
    } else {
      setRole('notfound');
    }
  }, [loginState, role]);

  if (typeof allowedRoles === 'object') {
    return <>{allowedRoles.includes(role) ? children : <Page404 />}</>;
  }
  return <>{allowedRoles() === role ? children : <Page404 />}</>;
};

export default RequireAuth;

/* eslint-disable react/prop-types */
import { Box, Button, Chip, Typography } from '@mui/material';

import React from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../feature/Alert/AlertSlice';
import { deleteLoader, setLoader } from '../../feature/Loader/LoaderSlice';
import randomkey from '../../helpers/randomKey';
import SessionService from '../../Services/SessionService';
import alertMock from '../../_mock/alert';
import ConvertFileInBase64 from '../../helpers/ConvertFileInBase64';
import Iconify from '../iconify/Iconify';
import StepperControle from './StepperControle';
import Reload from '../Reload/Reload';

const StepThree = (props) => {
  const [baseImage, setBaseImage] = React.useState([]);
  const [next, setNext] = React.useState(true);
  const [ready, setReady] = React.useState(true);
  const [reload, setreload] = React.useState(false);
  const [leasing, setLeasing] = React.useState(null);
  const dispatch = useDispatch();
  const LoaderKey = randomkey();

  const uploadImg = async (e) => {
    const file = e.target.files[0];
    const base64 = await ConvertFileInBase64(file);
    const { name } = e.target.files[0];
    const newState = { content: '', title: '' };
    newState.content = base64;
    newState.title = name;
    const newBaseImage = [...baseImage];
    newBaseImage.push(newState);
    setBaseImage(newBaseImage);
    setReady(false);
  };

  const handleDelete = React.useCallback(
    (index) => {
      const newState = [...baseImage];
      newState.splice(index, 1);
      setBaseImage(newState);
      if (newState.length === 0) {
        setReady(true);
      }
    },
    [baseImage]
  );

  const handleSubmit = React.useCallback(() => {
    if (leasing) {
      dispatch(setLoader({ key: LoaderKey }));
      SessionService.AddDocument(leasing, baseImage)
        .then((values) => {
          const { status } = values;
          const { data } = values.data;
          const dataStatus = values.data.error;
          dispatch(deleteLoader({ key: LoaderKey }));
          if ([404, 401, 500].includes(status)) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else if (dataStatus === true) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else {
            dispatch(setAlert({ state: 'success', message: data }));
            setNext(false);
            setreload(true);
          }
        })
        .catch((error) => {
          dispatch(deleteLoader({ key: LoaderKey }));
          if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
            if (error.response.data.error === true) {
              dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
            }
          } else {
            dispatch(
              setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
            );
          }
        });
    }
  }, [LoaderKey, baseImage, dispatch, leasing]);

  React.useEffect(() => {
    if (props.leasingId !== null) {
      setLeasing(props.leasingId);
    }
  }, [leasing, props]);
  return (
    <Box sx={{ display: props.activeStep === 2 ? 'block' : 'none' }}>
      {reload === true && <Reload />}
      <Typography variant="h5" sx={{ mb: 2, mt: 3 }}>
        Documents à soumettre
      </Typography>
      <Typography variant="h6" sx={{ mb: 5 }}>
        Veuillez nous fournir les indicateurs financiers suivants pour vos 3 dernières années.
      </Typography>
      <Box>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            rowGap: '5px',
            marginBottom: '10px',
          }}
        >
          {baseImage.map((item, key) => (
            <Chip
              icon={<Iconify icon={'ant-design:file-filled'} width={24} height={24} />}
              label={item.title}
              color={item.image === null ? 'secondary' : 'info'}
              onDelete={() => handleDelete(key)}
              variant="outlined"
              key={key}
            />
          ))}
        </div>
        <Button
          variant="outlined"
          component="label"
          color="info"
          sx={{ width: '100%', height: '150px' }}
          startIcon={<Iconify icon={'ant-design:file-filled'} width={24} height={24} onDelete={handleDelete} />}
        >
          <Typography sx={{ wordBreak: 'break-all' }}>
            Ajouter des fichiers
            <br /> (Must be a .doc,.docx,.xlxs,.text,.csv,.json,.xls,.jpg,.jpeg,.png,.svg)
          </Typography>
          <input
            hidden
            accept=".doc,.docx,.xlxs,.text.csv,.xls,.jpg,.jpeg,.png,.svg"
            multiple
            type="file"
            onChange={uploadImg}
          />
        </Button>
        <Button variant="contained" disabled={ready} sx={{ width: '100%', mt: '20px' }} onClick={handleSubmit}>
          Soumettre ma demande de crédit
        </Button>
      </Box>
      <StepperControle {...props} step={2} next={next} handleSubmit={() => {}} />
    </Box>
  );
};

export default StepThree;

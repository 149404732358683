import LoaderSlice from '../feature/Loader/LoaderSlice';
import AlertSlice from '../feature/Alert/AlertSlice';
import loginSlice from '../feature/login/loginSlice';
import DashboardSlice from '../feature/Dasboard/DashboardSlice';
import reloadSlice from '../feature/reload/reloadSlice';
import notificationSlice from '../feature/Notification/notificationSlice';

const { configureStore } = require('@reduxjs/toolkit');

const store = configureStore({
  reducer: {
    login: loginSlice,
    alert: AlertSlice,
    loader: LoaderSlice,
    dashboard: DashboardSlice,
    reload: reloadSlice,
    notification: notificationSlice,
  },
});

export default store;

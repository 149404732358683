import { useState } from 'react';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
// components
import { useDispatch } from 'react-redux';
import { deleteLoader, setLoader } from '../../../feature/Loader/LoaderSlice';
import { setAlert } from '../../../feature/Alert/AlertSlice';
import SessionService from '../../../Services/SessionService';
import Iconify from '../../../components/iconify';
import { addUser, login } from '../../../feature/login/loginSlice';
import randomkey from '../../../helpers/randomKey';
import alertMock from '../../../_mock/alert';
import { addNotification } from '../../../feature/Notification/notificationSlice';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const dispatch = useDispatch();
  const loginLoaderkey = randomkey();

  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = yup.object({
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    password: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: 's67986601@gmail.com',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => handleClick(values),
  });

  const handleClick = (values) => {
    dispatch(setLoader({ key: loginLoaderkey }));
    SessionService.Login(values)
      .then((values) => {
        const errorStatus = values.data.error;
        const { data } = values.data;
        const { notifications } = values.data;
        console.log(values.data,notifications)
        if (errorStatus === false) {
          dispatch(addUser({ token: data.accessToken, refreshToken: data.refreshToken }));
          dispatch(login());
          dispatch(deleteLoader({ key: loginLoaderkey }));
          dispatch(setAlert({ state: 'success', message: alertMock.successLogin, time: 15000 }));
          dispatch(addNotification({ notification: notifications }));
        } else {
          dispatch(deleteLoader({ key: loginLoaderkey }));
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: loginLoaderkey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
  };

  return (
    <Box component={'form'} onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Adresse email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Mot de passe"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <Link variant="subtitle2" underline="hover">
          Mot de passe oublié?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained">
        connexion
      </LoadingButton>
    </Box>
  );
}

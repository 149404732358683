/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
import React from 'react';

import {
  Avatar,
  Divider,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  TextField,
  ListItemText,
  Typography,
  Stack,
  CircularProgress,
  Badge,
} from '@mui/material';
import { filter } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../iconify/Iconify';
import { selectLogin } from '../../feature/login/loginSlice';
import { ADMIN, ASKER, INST, SUBINST } from '../../context/roles';
import SessionService from '../../Services/SessionService';
import { deleteLoader, setLoader } from '../../feature/Loader/LoaderSlice';
import { setAlert } from '../../feature/Alert/AlertSlice';
import { addInstlist, addUserList, selectDashboard } from '../../feature/Dasboard/DashboardSlice';
import randomkey from '../../helpers/randomKey';
import alertMock from '../../_mock/alert';
import { fDateTime } from '../../utils/formatTime';
import { deleteNotification, makeRead, selectNotification } from '../../feature/Notification/notificationSlice';

const classes = {
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '100%',
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  messageArea: {
    minHeight: 'calc(100% - 300px)',
    height: 'calc(100% - 100px)',
    overflow: 'hidden',
    overflowY: 'scroll',
  },
};

const Chat = () => {
  const { role } = useSelector(selectLogin);
  const dispatch = useDispatch();
  const [conversation, setConversation] = React.useState(null);
  const [selectUser, setSelectUser] = React.useState(null);
  const { userList, InstList } = useSelector(selectDashboard);
  const [user, setUser] = React.useState(null);
  const [filterUser, setFilterUser] = React.useState(null);
  const [message, setMessage] = React.useState('');
  const [userLoader, setUserLoader] = React.useState(true);
  const [conversationLoader, setConversationLoader] = React.useState(true);
  const [navSelect, setNavSelect] = React.useState(null);
  const [admin, setAdmin] = React.useState(null);
  const { notification } = useSelector(selectNotification);

  const LoaderKey = randomkey();

  React.useEffect(() => {
    if (role.name === ADMIN()) {
      SessionService.AllUser()
        .then((values) => {
          const { status } = values;
          const { data } = values.data;
          const dataStatus = values.data.error;
          if ([404, 401, 500].includes(status)) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else if (dataStatus === true) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else {
            dispatch(addUserList(data));
            otherRequest(data);
          }
        })
        .catch((error) => {
          setUserLoader(false);
          if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
            if (error.response.data.error === true) {
              dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
            }
          } else {
            dispatch(
              setAlert({
                state: 'error',
                message: alertMock.errorServeur,
                secondMessage: alertMock.errorServeurSecond,
              })
            );
          }
        });
    } else if (role.name === INST() || role.name === SUBINST()) {
      getUser();
    } else {
      getInst();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const getUser = React.useCallback(() => {
    SessionService.AllUser()
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          dispatch(addUserList(data));
          if (role.name !== ADMIN()) {
            getAdmin();
          } else {
            setUserLoader(false);
          }
        }
      })
      .catch((error) => {
        setUserLoader(false);
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInst = React.useCallback(() => {
    SessionService.ListInstitution()
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          dispatch(addUserList(data));
          if (role.name !== ADMIN()) {
            getAdmin();
          } else {
            setUserLoader(false);
          }
        }
      })
      .catch((error) => {
        setUserLoader(false);
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const otherRequest = React.useCallback(() => {
    SessionService.ListInstitution()
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          dispatch(addInstlist(data));
          setUserLoader(false);
        }
      })
      .catch((error) => {
        setUserLoader(false);
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({
              state: 'error',
              message: alertMock.errorServeur,
              secondMessage: alertMock.errorServeurSecond,
            })
          );
        }
      });
  }, []);

  React.useEffect(() => {
    if (userList !== null && InstList !== null) {
      const newArray = userList.concat(InstList);
      setUser(newArray);
      setFilterUser(newArray);
    } else if (userList !== null) {
      setUser(userList);
      setFilterUser(userList);
    }
  }, [userList, InstList]);

  React.useEffect(() => {
    if (selectUser !== null) {
      SessionService.GetMessage(parseInt(selectUser, 10))
        .then((values) => {
          const { status } = values;
          const { data } = values.data;
          const dataStatus = values.data.error;
          if ([404, 401, 500].includes(status)) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else if (dataStatus === true) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else {
            setConversation(false);
            setConversation(data);
            setNavSelect(selectUser);
            setSelectUser(null);
          }
        })
        .catch((error) => {
          if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
            if (error.response.data.error === true) {
              dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
            }
          } else {
            dispatch(
              setAlert({
                state: 'error',
                message: alertMock.errorServeur,
                secondMessage: alertMock.errorServeurSecond,
              })
            );
          }
        });
    }
  }, [selectUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage();
  };

  const sendMessage = () => {
    dispatch(setLoader({ key: LoaderKey }));
    SessionService.CreateMessage({ id: navSelect, message })
      .then((values) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          setConversation(data.chatMessages);
          setMessage('');
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({
              state: 'error',
              message: alertMock.errorServeur,
              secondMessage: alertMock.errorServeurSecond,
            })
          );
        }
      });
  };

  const handleFilter = (e) => {
    const { value } = e.target;
    const NewList = filter(user, (_user) => _user.manager_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    setFilterUser(NewList);
  };

  const getAdmin = React.useCallback(() => {
    if (role.name !== ADMIN()) {
      SessionService.GetAdmin()
        .then((values) => {
          const { status } = values;
          const { data } = values.data;
          const dataStatus = values.data.error;
          if ([404, 401, 500].includes(status)) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else if (dataStatus === true) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else {
            setUserLoader(false);
            setAdmin(data);
          }
        })
        .catch((error) => {
          setUserLoader(false);

          if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
            if (error.response.data.error === true) {
              dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
            }
          } else {
            dispatch(
              setAlert({
                state: 'error',
                message: alertMock.errorServeur,
                secondMessage: alertMock.errorServeurSecond,
              })
            );
          }
        });
    }
  }, [role.name]);

  // notification
  React.useEffect(() => {
    dispatch(makeRead());
  });

  const handleNotification = (user) => {
    const element = notification.find((item) => item.sender.id === user.tenant.id);
    const index = notification.indexOf(element);

    if (index > -1) {
      dispatch(deleteNotification({ key: element.sender.id }));
    }
  };

  return (
    <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      <Grid container component={Paper} sx={classes.chatSection}>
        <Grid item xs={3} sx={classes.borderRight500}>
          <Helmet>
            <title> Fasto Tableaux de bord </title>
          </Helmet>
          <Grid item xs={12} style={{ padding: '10px' }}>
            {admin !== null && (
              <>
                <Typography variant="h6" textAlign={'center'}>
                  {' '}
                  Administration
                </Typography>

                <ListItem
                  button
                  key={admin.manager_name}
                  onClick={() => {
                    setSelectUser(admin.tenant.id);
                    setConversation(null);
                    setConversationLoader(true);
                    handleNotification(admin);
                  }}
                  disabled={selectUser !== null}
                  selected={navSelect === admin.tenant.id}
                >
                  <ListItemIcon>
                    <Badge
                      badgeContent={notification.map((notice) =>
                        notice.sender.id === admin.tenant.id && notice?.read !== true ? notice.messageCount : null
                      )}
                      color={
                        notification.find((notice) => notice.sender.id === admin.tenant.id && notice?.read !== true)
                          ? 'primary'
                          : 'default'
                      }
                    >
                      <Avatar alt={admin.manager_name} src={'/assets/images/avatars/avatar_1.jpg'} />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary={admin.manager_name}>{admin.manager_name}</ListItemText>
                  <ListItemText secondary={admin.tenant.legal_name} align="right" />
                </ListItem>
              </>
            )}
            <TextField
              id="outlined-basic-email"
              label="Recherche"
              variant="outlined"
              fullWidth
              onChange={handleFilter}
            />
          </Grid>
          <Divider />
          {filterUser !== null && userLoader === false && (
            <List sx={{ maxHeight: '100%', overflow: 'hidden', overflowY: 'scroll' }}>
              <Typography variant="h6" textAlign={'center'}>
                {role.name === ASKER() ? 'Les établissements de crédit' : 'Les entreprises'}
              </Typography>

              {filterUser.map((item, key) => (
                <ListItem
                  button
                  key={item.manager_name}
                  onClick={() => {
                    setSelectUser(item.tenant.id);
                    setConversation(null);
                    setConversationLoader(true);
                    handleNotification(item);
                  }}
                  disabled={selectUser !== null}
                  selected={navSelect === item.tenant.id}
                >
                  <ListItemIcon>
                    <Badge
                      badgeContent={notification.map((notice) =>
                        notice.sender.id === item.tenant.id && notice?.read !== true ? notice.messageCount : null
                      )}
                      key={key}
                      color={
                        notification.find((notice) => notice.sender.id === item.tenant.id && notice?.read !== true)
                          ? 'primary'
                          : 'default'
                      }
                    >
                      <Avatar alt={item.manager_name} src={`/assets/images/avatars/avatar_${key + 2}.jpg`} />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary={item.manager_name}>{item.manager_name}</ListItemText>
                  <ListItemText secondary={item.tenant.legal_name} align="right" />
                </ListItem>
              ))}
            </List>
          )}
          {userLoader === true && (
            <Stack sx={{ width: '100%', height: '100%' }} justifyContent={'center'} alignItems={'center'}>
              <CircularProgress color="inherit" />
            </Stack>
          )}
        </Grid>
        <Grid item xs={9} sx={{ height: '100%', overflow: 'hidden' }}>
          {conversation !== null && (
            <>
              <List sx={classes.messageArea}>
                {conversation.map((item, key) => (
                  <ListItem key={key}>
                    <Grid container>
                      <Grid item xs={12}>
                        <ListItemText align={item.receiver === navSelect ? 'right' : 'left'} primary={item.content} />
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemText
                          align={item.receiver === navSelect ? 'right' : 'left'}
                          secondary={fDateTime(item.created_at)}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
              <Divider />
              <Grid container sx={{ height: '100px', padding: '20px 20px' }} component={'form'} onSubmit={handleSubmit}>
                <Grid item xs={10}>
                  <TextField
                    id="outlined-basic-email"
                    label="Message..."
                    fullWidth
                    sx={{ width: '100%' }}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    focused
                  />
                </Grid>
                <Grid item xs={2} align="right">
                  <Fab color="primary" aria-label="add" type="submit">
                    <Iconify icon={'ic:baseline-send'} width="30px" />
                  </Fab>
                </Grid>
              </Grid>
            </>
          )}
          {conversation === null && selectUser === null && (
            <Stack justifyContent={'center'} alignItems={'center'} sx={{ height: '100%' }}>
              <Typography color={'info'} variant="h5">
                Aucune conversation sélectionnée pour le moment
              </Typography>
            </Stack>
          )}

          {conversationLoader === true && selectUser !== null && (
            <Stack sx={{ width: '100%', height: '100%' }} justifyContent={'center'} alignItems={'center'}>
              <CircularProgress color="inherit" />
            </Stack>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Chat;

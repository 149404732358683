/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { Box, Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLoader, setLoader } from '../../feature/Loader/LoaderSlice';
import randomkey from '../../helpers/randomKey';
import ConvertFileInBase64 from '../../helpers/ConvertFileInBase64';
import { setAlert } from '../../feature/Alert/AlertSlice';
import alertMock from '../../_mock/alert';
import SessionService from '../../Services/SessionService';
import Reload from '../Reload/Reload';
import { selectLogin } from '../../feature/login/loginSlice';
import { ASKER } from '../../context/roles';

// eslint-disable-next-line react/prop-types
const ShowDocuments = ({ data, handleClose, leasing }) => {
  const [imageList, setImageList] = React.useState(data);
  const [change, setChange] = React.useState(true);
  const [reload, setreload] = React.useState(false);
  const { role } = useSelector(selectLogin);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setImageList(data);
  }, [data]);

  const handleDelete = React.useCallback(
    (id) => {
      const List = [...imageList];
      List.splice(id, 1);
      setImageList(List);
      setChange(false);
    },
    [imageList]
  );

  const uploadImg = async (e) => {
    const file = e.target.files[0];
    const base64 = await ConvertFileInBase64(file);
    const { name } = e.target.files[0];
    const newState = { title: '', content: '', new: true };
    newState.content = base64;
    newState.title = name;
    const List = [...imageList, newState];
    setImageList(List);
    setChange(false);
  };

  const handleSubmit = () => {
    const LoaderKey = randomkey();
    dispatch(setLoader({ key: LoaderKey }));
    SessionService.AddDocument(leasing, imageList)
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          dispatch(setAlert({ state: 'success', message: data }));
          handleClose();
          setreload(true);
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
  };

  return (
    <Box>
      {reload === true && <Reload />}
      <>
        <Typography variant="h4" sx={{ marginBottom: '10px' }}>
          Les documents
        </Typography>
        <Box>
          <Box component="div">la liste de tous les fichiers envoyés pour cette demande de crédit.</Box>
          {role.name === ASKER() && (
            <Stack flexDirection={'row'} flexWrap="wrap" gap={'10px 20px'} sx={{ mt: '20px' }}>
              <Button variant="contained" component="label">
                Ajouter un nouveau fichier
                <input hidden multiple type="file" onChange={uploadImg} />
              </Button>
              <Button variant="contained" color="success" disabled={change} onClick={handleSubmit}>
                Sauvegarder
              </Button>
            </Stack>
          )}
          <Stack
            flexDirection={'row'}
            justifyContent="flex-start"
            alignItems={'center'}
            rowGap="20px"
            flexWrap={'wrap'}
            sx={{ mt: '30px', columnGap: 'calc(10%/3)' }}
          >
            {imageList.map((item, key) => (
              <ImageCard item={item} key={key} DeleteFile={() => handleDelete(key)} />
            ))}
          </Stack>
        </Box>
      </>
    </Box>
  );
};

const ImageCard = ({ item, DeleteFile }) => {
  const { role } = useSelector(selectLogin);
  const handleDownload = () => {
    downloadFile(item.content, item.title, getContentType(item.title));
  };
  return (
    <Card sx={{ width: 'calc(90% /3)', minWidth: { xs: '100%', sm: '45%', lg: 'auto' } }}>
      <Stack justifyContent={'center'} alignItems="center" sx={{ mt: '20px' }}>
        <img src="/assets/images/file.svg" alt="" style={{ height: '150px', width: 'auto' }} />
      </Stack>
      <CardContent>
        <Typography gutterBottom component="p" sx={{ wordBreak: 'break-word' }}>
          {item.title}
        </Typography>
      </CardContent>
      <CardActions sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
        <Button size="small" color="info" variant="contained" onClick={handleDownload} disabled={item?.new}>
          Télécharger
        </Button>
        {role.name === ASKER() && (
          <Button size="small" color="error" variant="contained" onClick={DeleteFile}>
            Suprimer
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

function downloadFile(base64String, filename, contentType) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: contentType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function getContentType(fileName) {
  const extension = fileName.split('.').pop();
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'txt':
      return 'text/plain';
    case 'csv':
      return 'text/csv';
    case 'json':
      return 'application/json';
    case 'xls':
      return 'application/vnd.ms-excel';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'doc':
      return 'application/msword';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'png':
      return 'image/png';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'gif':
      return 'image/gif';
    case 'svg':
      return 'image/svg+xml';
    default:
      return 'application/octet-stream';
  }
}

export default ShowDocuments;

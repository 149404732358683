const { createSlice } = require('@reduxjs/toolkit');

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notification: [{ sender: { id: 'defaultValue' } }],
    read: false,
  },
  reducers: {
    addNotification(state, action) {
      state.notification = action.payload.notification;
    },
    makeRead(state) {
      state.read = true;
    },
    deleteNotification(state, action) {
      const element = state.notification.find((element) => element.sender.id === action.payload.key);

      const index = state.notification.indexOf(element);
      element.read = true;
      if (index > -1) {
        state.notification[index] = element;
      }
    },
  },
});

export const { addNotification, makeRead, deleteNotification } = notificationSlice.actions;

export const selectNotification = (state) => state.notification;

export default notificationSlice.reducer;

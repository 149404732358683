/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextareaAutosize,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import * as yup from 'yup';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import StepperControle from './StepperControle';
import { selectLogin } from '../../feature/login/loginSlice';
import uuidv4 from '../../helpers/uuidv4';
import SessionService from '../../Services/SessionService';
import { deleteLoader, setLoader } from '../../feature/Loader/LoaderSlice';
import randomkey from '../../helpers/randomKey';
import { setAlert } from '../../feature/Alert/AlertSlice';
import alertMock from '../../_mock/alert';
import ConfirmAlert from '../ConfirmAlert/ConfirmAlert';

const StepTwo = (props) => {
  const [next, setNext] = React.useState(true);
  const { userInfo } = useSelector(selectLogin);
  const [demandeInfo, setDemandeInfo] = React.useState({
    status: false,
    message: '',
  });
  const [DocumentList, setDocumentList] = React.useState({});
  const dispatch = useDispatch();
  const LoaderKey = randomkey();
  const demandeLoaderKey = randomkey();
  const [uuid, setUuid] = React.useState(uuidv4());

  React.useEffect(() => {
    setDocumentList(props.stepOneData);
  }, [props.stepOneData]);

  const initialValues = {
    rolling_stock: '',
    new_equipment: '',
    local_provider: '',
    legal_provider: '',
    equipement_description: '',
    equipement_web_link: '',
    funding_period: '',
    funding_amount: '',
    capital: '',
    last_contract_balance: '',
    title: '',
    outstanding_loans: '',
    outstanding_loans_amount: '',
    default_to_pay: '',
  };

  const handleSubmit = (equipementInfo) => {
    equipementInfo.outstanding_loans = equipementInfo.outstanding_loans === 'true';
    equipementInfo.default_to_pay = equipementInfo.default_to_pay === 'true';
    const FormData = {
      lease_uuid: uuid,
      tenant_id: userInfo.all.tenant.id,
      ...DocumentList,
      compagny_creation_date: userInfo.all.tenant.compagny_creation_date,
      min_3_years_existance: userInfo.all.tenant.min_3_years_existance,
      ...equipementInfo,
    };

    dispatch(setLoader({ key: LoaderKey }));
    SessionService.CreatRequest(FormData)
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          dispatch(
            setAlert({
              state: data.status === 'green' ? 'success' : data.status === 'orange' ? 'warning' : 'error',
              message: data.message,
              time: '10000',
            })
          );

          if (data.status === 'orange') {
            setNext(false);
            setDemandeInfo({
              status: true,
              message:
                'Félicitations, vous êtes sur la bonne voie ! Cependant, pour aller encore plus loin, il peut être bénéfique pour vous de travailler avec un cabinet pour vous accompagner. Si cela vous intéresse, vous pouvez cliquer sur "Accepter" pour en savoir plus sur les options de cabinet disponibles. Si vous préférez continuer seul pour le moment, vous pouvez cliquer sur "Refuser".',
            });
          } else if (data.status === 'green') {
            setNext(false);
          }
          props.setLeasingId(data.leasing_request_id);
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
  };

  const validationSchema = yup.object().shape({
    rolling_stock: yup.string().required('Ce champ est requis'),
    new_equipment: yup.string().required('Ce champ est requis'),
    local_provider: yup.string().required('Ce champ est requis'),
    legal_provider: yup.string().required('Ce champ est requis'),
    equipement_description: yup.string().required('Ce champ est requis'),
    equipement_web_link: yup.string().url('Entrez une URL valide'),
    funding_period: yup.number().required('Ce champ est requis').positive().integer(),
    funding_amount: yup.number().required('Ce champ est requis').positive(),
    capital: yup.number().required('Ce champ est requis').positive(),
    last_contract_balance: yup.number().required('Ce champ est requis').positive(),
    title: yup.string().required('Ce champ est requis'),
    outstanding_loans: yup.string().required('Ce champ est requis'),
    outstanding_loans_amount: yup.number().when('outstanding_loans', {
      is: 'oui',
      then: (schema) => schema.required('Ce champ est requis').positive(),
    }),
    default_to_pay: yup.string().required('Ce champ est requis'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleConfirm = (accept) => {
    if (accept) {
      dispatch(setLoader({ key: demandeLoaderKey }));
      SessionService.CreateAssitance(uuid)
        .then((values) => {
          const { status } = values;
          const { data } = values.data;
          const dataStatus = values.data.error;
          dispatch(deleteLoader({ key: demandeLoaderKey }));
          if ([404, 401, 500].includes(status)) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else if (dataStatus === true) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          } else {
            dispatch(setAlert({ state: 'success', message: data }));
            setNext(false);
          }
        })
        .catch((error) => {
          dispatch(deleteLoader({ key: demandeLoaderKey }));
          if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
            if (error.response.data.error === true) {
              dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
            }
          } else {
            dispatch(
              setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
            );
          }
        });
    }
  };

  const handleNext = () => {};

  return (
    <Box sx={{ display: props.activeStep === 1 ? 'block' : 'none' }}>
      {demandeInfo.status === true && <ConfirmAlert message={demandeInfo.message} onClose={handleConfirm} />}
      <Typography variant="h5" sx={{ mb: 2, mt: 3 }}>
        Les Informations
      </Typography>
      <Typography variant="h6" sx={{ mb: 5 }}>
        Saisissez les informations nécessaires concernant votre demande de crédit afin de vérifier votre éligibilité.
        <span style={{ color: 'red' }}> *</span>
      </Typography>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems="flex-start"
        sx={{ width: '100%', margin: '5vh auto auto auto ', flexWrap: 'wrap', rowGap: '20px' }}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>
            Équipement(s) souhaite(s) <br /> <span style={{ opacity: '0' }}>*</span>
          </FormLabel>
          <TextField
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
        </FormControl>

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>S’agit-il de matériel(s) roulant(s) ?</FormLabel>
          <RadioGroup name="rolling_stock" value={formik.values.rolling_stock} onChange={formik.handleChange}>
            <FormControlLabel value="1" error control={<Radio error />} label="Oui" />
            <FormControlLabel value="0" control={<Radio />} label="Non" />
          </RadioGroup>
          {formik.touched.rolling_stock && Boolean(formik.errors.rolling_stock) ? (
            <div style={{ color: 'red' }}>{formik.errors.rolling_stock}</div>
          ) : null}
        </FormControl>

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>S’agit-il de matériel neuf</FormLabel>
          <RadioGroup name="new_equipment" value={formik.values.new_equipment} onChange={formik.handleChange}>
            <FormControlLabel value="1" control={<Radio />} label="Oui" />
            <FormControlLabel value="0" control={<Radio />} label="Non" />
          </RadioGroup>
          {formik.touched.new_equipment && Boolean(formik.errors.new_equipment) ? (
            <div style={{ color: 'red' }}>{formik.errors.new_equipment}</div>
          ) : null}
        </FormControl>

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>Le fournisseur est-il local (présence en Cote d’Ivoire) ?</FormLabel>
          <RadioGroup name="local_provider" value={formik.values.local_provider} onChange={formik.handleChange}>
            <FormControlLabel value="oui" control={<Radio />} label="Oui" />
            <FormControlLabel value="non" control={<Radio />} label="Non" />
            <FormControlLabel value="je ne sais pas" control={<Radio />} label="Je ne sais pas" />
          </RadioGroup>
          {formik.touched.local_provider && Boolean(formik.errors.local_provider) ? (
            <div style={{ color: 'red' }}>{formik.errors.local_provider}</div>
          ) : null}
        </FormControl>

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>Le fournisseur est-il un professionnel représentant légalement la marque</FormLabel>
          <RadioGroup name="legal_provider" value={formik.values.legal_provider} onChange={formik.handleChange}>
            <FormControlLabel value="oui" control={<Radio />} label="Oui" />
            <FormControlLabel value="non" control={<Radio />} label="Non" />
            <FormControlLabel value="je ne sais pas" control={<Radio />} label="Je ne sais pas" />
          </RadioGroup>
          {formik.touched.legal_provider && Boolean(formik.errors.legal_provider) ? (
            <div style={{ color: 'red' }}>{formik.errors.legal_provider}</div>
          ) : null}
        </FormControl>

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>Description de la nature de(s) équipement(s)</FormLabel>
          <TextField
            id="equipement_description"
            name="equipement_description"
            sx={{
              width: '100%',
            }}
            value={formik.values.equipement_description}
            onChange={formik.handleChange}
            error={formik.touched.equipement_description && Boolean(formik.errors.equipement_description)}
            helperText={formik.touched.equipement_description && formik.errors.equipement_description}
            InputProps={{
              inputComponent: TextareaAutosize,
              inputProps: {
                style: {
                  width: '100%',
                  height: '100px',
                  borderSize: '2px',
                  borderRadius: '5px',
                },
              },
            }}
          />
        </FormControl>

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>Un lien web de(s) équipement(s) (optionnel)</FormLabel>
          <TextField
            name="equipement_web_link"
            value={formik.values.equipement_web_link}
            onChange={formik.handleChange}
            error={formik.touched.equipement_web_link && Boolean(formik.errors.equipement_web_link)}
            helperText={formik.touched.equipement_web_link && formik.errors.equipement_web_link}
          />
        </FormControl>

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>
            Quelle est la durée de financement souhaitée (36 mois ou moins est la durée généralement admise)
          </FormLabel>
          <TextField
            type="number"
            name="funding_period"
            value={formik.values.funding_period}
            onChange={formik.handleChange}
            error={formik.touched.funding_period && Boolean(formik.errors.funding_period)}
            helperText={formik.touched.funding_period && formik.errors.funding_period}
          />
        </FormControl>

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>
            Quel est le montant du financement sollicité <br /> <span style={{ opacity: '0' }}>*</span>
          </FormLabel>
          <TextField
            type="number"
            name="funding_amount"
            value={formik.values.funding_amount}
            onChange={formik.handleChange}
            error={formik.touched.funding_amount && Boolean(formik.errors.funding_amount)}
            helperText={formik.touched.funding_amount && formik.errors.funding_amount}
          />
        </FormControl>

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>Quel est le montant de vos capitaux propres/fonds propres ?</FormLabel>
          <TextField
            type="number"
            name="capital"
            value={formik.values.capital}
            onChange={formik.handleChange}
            error={formik.touched.capital && Boolean(formik.errors.capital)}
            helperText={formik.touched.capital && formik.errors.capital}
          />
        </FormControl>

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>
            Quel est votre total bilan de l’exercice dernier ? <br /> <span style={{ opacity: '0' }}>*</span>
          </FormLabel>
          <TextField
            type="number"
            name="last_contract_balance"
            value={formik.values.last_contract_balance}
            onChange={formik.handleChange}
            error={formik.touched.last_contract_balance && Boolean(formik.errors.last_contract_balance)}
            helperText={formik.touched.last_contract_balance && formik.errors.last_contract_balance}
          />
        </FormControl>

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>Avez vous déjà des encours de crédits avec des banques et institutions financières ?</FormLabel>
          <RadioGroup name="outstanding_loans" value={formik.values.outstanding_loans} onChange={formik.handleChange}>
            <FormControlLabel value="oui" error control={<Radio error />} label="Oui" />
            <FormControlLabel value="non" control={<Radio />} label="Non" />
          </RadioGroup>
        </FormControl>

        {formik.values.outstanding_loans === 'oui' && (
          <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
            <FormLabel>
              Si oui, quel est le montant ?<br /> <span style={{ opacity: '0' }}>*</span>
            </FormLabel>
            <TextField
              type="number"
              name="outstanding_loans_amount"
              value={formik.values.outstanding_loans_amount}
              onChange={formik.handleChange}
              error={formik.touched.outstanding_loans_amount && Boolean(formik.errors.outstanding_loans_amount)}
              helperText={formik.touched.outstanding_loans_amount && formik.errors.outstanding_loans_amount}
            />
          </FormControl>
        )}

        <FormControl sx={{ width: { xs: '100%', md: '47.5%' } }}>
          <FormLabel>Avez vous fait l’objet d'une cessation de paiement ou d'un règlement préventif ?</FormLabel>
          <RadioGroup name="default_to_pay" value={formik.values.default_to_pay} onChange={formik.handleChange}>
            <FormControlLabel value="1" control={<Radio />} label="Oui" />
            <FormControlLabel value="0" control={<Radio />} label="Non" />
          </RadioGroup>
          {formik.touched.default_to_pay && Boolean(formik.errors.default_to_pay) ? (
            <div style={{ color: 'red' }}>{formik.errors.default_to_pay}</div>
          ) : null}
        </FormControl>

        <Button type="submit" variant="contained" sx={{ width: '100%' }}>
          Soumettre
        </Button>
      </Stack>

      <StepperControle {...props} step={1} next={next} handleSubmit={handleNext} />
    </Box>
  );
};

export default StepTwo;

import PropTypes from 'prop-types';
import React from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Badge } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import { ChatRouteLink } from '../../../Router/routes';
import Redirect from '../../../helpers/Redirect';
import { selectNotification } from '../../../feature/Notification/notificationSlice';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  zIndex: 5,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { pathname } = useLocation();
  const { notification, read } = useSelector(selectNotification);
  const [count, setCount] = React.useState(null);

  React.useEffect(() => {
    if (notification !== null) {
      if (read === false) {
        setCount([...notification].length);
      } else {
        setCount(null);
      }
    }
  }, [notification, read]);

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <NotificationsPopover /> */}
          <Redirect link={ChatRouteLink()}>
            <IconButton
              size="large"
              color={pathname === ChatRouteLink() ? 'primary' : 'default'}
              sx={{
                ...(pathname === ChatRouteLink() && {
                  bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
                }),
              }}
            >
              <Badge badgeContent={count} color="error">
                <Iconify icon="ic:round-textsms" width={20} height={20} />
              </Badge>
            </IconButton>
          </Redirect>
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}

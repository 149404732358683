/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import SessionService from '../Services/SessionService';
import { setAlert } from '../feature/Alert/AlertSlice';
import alertMock from '../_mock/alert';
import { addGroupeList, addInstlist, addStats, selectDashboard } from '../feature/Dasboard/DashboardSlice';
import { deleteLoader, setLoader } from '../feature/Loader/LoaderSlice';
import randomkey from '../helpers/randomKey';
import AdminInstTable from '../sections/AdminInstTable';
import { offReload, selectReload } from '../feature/reload/reloadSlice';
import { INST } from '../context/roles';

// ----------------------------------------------------------------------

export default function AdminInst() {
  const dispatch = useDispatch();
  const { stats } = useSelector(selectDashboard);
  const LoaderKey = randomkey();
  const { adminInstitution } = useSelector(selectReload);

  React.useEffect(() => {
    if (adminInstitution === true) {
      dispatch(setLoader({ key: LoaderKey }));
      SessionService.Statics()
        .then((values) => {
          const { status } = values;
          const { data } = values.data;
          const dataStatus = values.data.error;
          if ([404, 401, 500].includes(status)) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
            dispatch(deleteLoader({ key: LoaderKey }));
          } else if (dataStatus === true) {
            dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
            dispatch(deleteLoader({ key: LoaderKey }));
          } else if (data.length === 0) {
            const states = { total: 0 };
            dispatch(addStats(states));
            dispatch(deleteLoader({ key: LoaderKey }));
          } else {
            const states = { total: 0 };
            const total = data.find((item) => item.role === INST());
            states.total = total?.count !== undefined ? total?.count : 0;
            dispatch(addStats(states));
            requestList();
          }
        })
        .catch((error) => {
          dispatch(deleteLoader({ key: LoaderKey }));
          if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
            if (error.response.data.error === true) {
              dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
            }
          } else {
            dispatch(
              setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
            );
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminInstitution]);

  const requestList = React.useCallback(() => {
    SessionService.ListInstitution()
      .then((values) => {
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(deleteLoader({ key: LoaderKey }));
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else if (dataStatus === true) {
          dispatch(deleteLoader({ key: LoaderKey }));
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
        } else {
          dispatch(addInstlist(data));
          GroupeList();
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   const getUserInfo = React.useCallback(() => {}, []);
  const GroupeList = React.useCallback(() => {
    SessionService.ListGroupe()
      .then((values) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        const { status } = values;
        const { data } = values.data;
        const dataStatus = values.data.error;
        if ([404, 401, 500].includes(status)) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          dispatch(deleteLoader({ key: LoaderKey }));
        } else if (dataStatus === true) {
          dispatch(setAlert({ state: 'error', message: alertMock.statsError }));
          dispatch(deleteLoader({ key: LoaderKey }));
        } else {
          dispatch(addGroupeList(data));
          dispatch(deleteLoader({ key: LoaderKey }));
          dispatch(offReload(['adminInstitution']));
        }
      })
      .catch((error) => {
        dispatch(deleteLoader({ key: LoaderKey }));
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            dispatch(setAlert({ state: 'error', message: error?.response?.data.message }));
          }
        } else {
          dispatch(
            setAlert({ state: 'error', message: alertMock.errorServeur, secondMessage: alertMock.errorServeurSecond })
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <>
      <Grid>
        <Helmet>
          <title> Fasto Tableaux de bord </title>
        </Helmet>

        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Bonjour, bienvenue
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total Institution"
                color="info"
                total={stats.total === 0 ? '0' : stats.total}
                icon={'ant-design:audit-outlined'}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid sx={{ marginTop: '50px' }}>
        <AdminInstTable />
      </Grid>
    </>
  );
}

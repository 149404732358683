/* eslint-disable react/prop-types */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

function ConfirmAlert(props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = (accept) => {
    setOpen(false);
    props.onClose(accept);
  };

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose(false)} sx={{ zIndex: '99999999999999999' }}>
        <DialogTitle>{[undefined, null, ''].includes(props.title) ? "Confirmer l'action" : props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="error" variant="contained">
            Annuler
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus variant="contained">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmAlert;

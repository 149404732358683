/* eslint-disable react/prop-types */
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import React from 'react';
import StepperControle from './StepperControle';

const StepOne = (props) => {
  const [next, setNext] = React.useState(true);
  const [documentList, setDocumentList] = React.useState([
    { property: 'managerID', title: 'Photocopie de la pièce du gérant ou du chef d’entreprise (scan)', status: false },
    { property: 'compagny_statutes', title: 'Copie des statuts de la société', status: false },
    {
      property: 'commercial_register',
      title: 'Registre de commerce authentifié par le tribunal du commerce (scan)',
      status: false,
    },
    { property: 'tax_declaration', title: "Déclaration d'existence fiscale DFE (scan)", status: false },
    { property: 'g_map', title: 'Plan de localisation Google Maps', status: false },
    { property: 'receipt', title: 'Copie d’une quittance CIE/SODECI (scan)', status: false },
    { property: 'rib_iban', title: 'Relevé d’identité bancaire (RIB ou IBAN)', status: false },
    {
      property: 'bank_statements',
      title: 'Relevés bancaires des 6 derniers mois (totaux mouvements et soldes/mois)',
      status: false,
    },
    {
      property: 'financial_statement',
      title: 'États financiers visés par un cabinet comptable des 3 derniers exercices (liasse complète syscohada)',
      status: false,
    },
    { property: 'general_balance', title: 'Situation à mi-parcours ou Balance générale des comptes', status: false },
    { property: 'business_plan', title: 'Prévisionnel (business plan)', status: false },
    { property: 'in_execution_contracts', title: 'États des marchés en cours d’exécution', status: false },
    { property: 'machinery', title: 'Etat du parc auto et du parc machines en exploitation', status: false },
  ]);

  const handleChange = React.useCallback(
    (event, item) => {
      const value = event.target.checked;
      const index = documentList.findIndex((currentItem) => currentItem.title === item.title);
      const newDocumentList = [...documentList];
      newDocumentList[index].status = value;
      setDocumentList(newDocumentList);
      const isChange = documentList.findIndex((currentItem) => currentItem.status === true);
      if (isChange >= 0) {
        setNext(false);
      } else {
        setNext(true);
      }
    },
    [documentList]
  );

  const handleSubmit = React.useCallback(() => {
    if (next === false) {
      const newDocumentList = {};
      documentList.forEach((item) => {
        newDocumentList[item.property] = item.status;
      });
      props.handleAddStepOne(newDocumentList);
    }
  }, [documentList, next, props]);

  return (
    <Box sx={{ display: props.activeStep === 0 ? 'block' : 'none' }}>
      <Box>
        <Typography variant="h5" sx={{ mb: 2, mt: 3 }}>
          Vos documents
        </Typography>
        <Typography variant="h6" sx={{ mb: 5 }}>
          Pouvez-vous fournir les documents suivants plus tard sur demande (cocher oui si vous les avez)
          <span style={{ color: 'red' }}> *</span>
        </Typography>

        <FormGroup>
          {documentList.map((item, key) => (
            <FormControlLabel
              key={key}
              control={<Checkbox onChange={(event) => handleChange(event, item)} checked={item.status} />}
              label={item.title}
            />
          ))}
        </FormGroup>
      </Box>
      <StepperControle {...props} step={0} next={next} handleSubmit={handleSubmit} />
    </Box>
  );
};

export default StepOne;

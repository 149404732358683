import { createSlice } from '@reduxjs/toolkit';
import randomkey from '../../helpers/randomKey';

const initialState = [
  //  default state
  //   {
  //     state: "error",
  //     message: "hello world what are you doing",
  //     secondMessage: "second message for the alert",
  //     time: 1000
  //   },
];

const AlertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert(state, action) {
      if (!Object.keys(action.payload).includes('secondMessage')) {
        action.payload.secondMessage = null;
      }
      if(!Object.keys(action.payload).includes('time')){
        action.payload.time = 5000;
      }
      state.push({ ...action.payload, key: randomkey() });
    },
    deleteAlert(state, action) {
      const element = state.find((element) => element.key === action.payload.key);

      const index = state.indexOf(element);
      if (index > -1) {
        state.splice(index, 1);
      }
    },
  },
});

export const { setAlert, deleteAlert } = AlertSlice.actions;

export const selectAlert = (state) => state.alert;

export default AlertSlice.reducer;
